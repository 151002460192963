.assessment-main-wrap .p-datatable .p-datatable-thead > tr > th {
  background-color: #000;
  color: #fff;
  padding: 16px 5px;
}
.assessment-main-wrap .p-datatable .p-datatable-thead th.main-header:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.assessment-main-wrap .p-datatable .p-datatable-thead th.main-header:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.assessment-main-wrap .p-datatable .p-datatable-tbody > tr > td {
  padding: 4px 5px;
}
.add-new-assessment-btn {
  padding: 4px;
  line-height: 32px;
  background: #000;
  display: inline-block;
  width: 173px;
  text-align: center;
  border: none;
  color: #fff;
  border-radius: 8px;
}
.assessment-main-wrap .p-datatable .p-datatable-tbody td.new-assessment-td {
  text-align: end;
  border: none;
  padding-top: 12px;
}

.p-datatable .p-datatable-tbody > tr.assessment-list-top {
  background-color: rgba(35, 31, 32, 0.06);
}

.assessment-main-wrap .p-datatable .p-datatable-thead > tr > th.assessment-list-top.w-100px {
  width: 100px;
}

.assessment-main-wrap .p-datatable .p-datatable-thead > tr > th.assessment-list-top {
  background-color: rgba(35, 31, 32, 0.06);
  color: #222;
}

.assessment-main-wrap .p-datatable .p-datatable-tbody > tr > td.noRecord {
  text-align: center;
  padding: 10px;
}

@media (max-width: 767px) {
  .ass-tabel .p-element tr td:before {
    content: ' ' attr(data-th) ' : ';
    display: inline-block;
    font-weight: 600;
    margin-right: 10px;
    color: #222;
  }
  #pr_id_1 .p-datatable-tbody > tr > td {
    justify-content: unset !important;
  }
  .ass-tabel .p-element tr td.before-none-767:before {
    display: none;
  }
  .w-100-767 {
    width: 100%;
  }
  .p-2.w-100-767 {
    width: 100%;
    padding-left: 0 !important;
  }
  .p-button {
    padding: 5px 10px;
  }
}
