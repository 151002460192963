:root {
  --primary-color: #7ed957;
  --primary-bg-color: #000;
  --color-white: #ffffff;
  --primary-font-color: #ffffffb2;
  --boder-color: #e2e8f0;
  --hover-color: #d94645;
  --secondery-bg-color: #0c0c0c;
  --form-fild-color: #ffffff14;
  --form-text-color: #52525266;
}
.min-100 {
  min-height: 100vh;
}
.text-uppercase {
  text-transform: uppercase;
}
.f-22-400 {
  font-size: 22px;
  font-weight: 400;
}
.container {
  width: 100%;
  max-width: 1310px;
  padding: 0 15px;
  margin: 0 auto;
}
.primary-btn {
  background: var(--primary-color);
  color: var(--primary-bg-color);
  font-weight: 700;
  border-radius: 12px;
  padding: 12px 43px;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: clamp(18px, 1.25vw, 24px);
  line-height: clamp(28px, 1.97vw, 30px);
}
.secondery-btn {
  display: inline-block;
  /* font-size: 18px; */
  font-weight: 300;
  /* line-height: 27px; */
  padding: 10px 24px;
  border: 0.87px solid var(--boder-color);
  color: var(--boder-color);
  border-radius: 35px;
  text-decoration: none;
  font-size: clamp(14px, 0.93vw, 18px);
  line-height: clamp(28px, 1.97vw, 27px);
}
.primary-bg {
  background: var(--primary-bg-color);
}
.secondery-bg {
  background: var(--secondery-bg-color);
}
/* list class  */
.list-style-none {
  list-style: none;
}

/* display class  */
// .d-flex {
//   display: flex;
//   flex-wrap: wrap;
// }
.justify-spacebetween {
  justify-content: space-between;
}
.align-item-center {
  align-items: center;
}

/* margin class */
.mr2 {
  margin-right: 16px;
}
.mr3 {
  margin-right: 20px;
}
/* padding class */
.p5 {
  padding-top: 94px;
  padding-bottom: 94px;
}
.pl-2 {
  padding-left: 16px;
}
/* text class  */
.text-center {
  text-align: center;
}
.w-100 {
  width: 100%;
}
.f-30-700 {
  font-size: 30px;
  font-weight: 700;
  line-height: 33px;
}
.border-radius-8 {
  border-radius: 8px;
}
.ass-tabel {
  max-height: calc(100vh - 225px);
  overflow: auto;
}
.ass-tabel thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.ass-tabel tbody thead {
  position: unset;
  top: 0;
  z-index: 0;
}
/* heading class  */
.h2 {
  font-size: clamp(40px, 4.37vw, 84px);
  font-weight: 700;
  line-height: clamp(40px, 5.56vw, 126px);
  color: var(--color-white);
}
/* paragra class  */
.primary-font-color {
  color: var(--primary-font-color);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
}
.d-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}

.bg-main {
  background: #000;
}
.bg-secondary-only {
  background: #0f0f0f;
}
.w-40 {
  width: 40%;
}
.w-60 {
  width: 60%;
}
.f-18-400 {
  font-size: 18px;
  font-weight: 400;
}
.f-18-600 {
  font-size: 18px;
  font-weight: 600;
}
.f-20-700 {
  font-size: 20px;
  font-weight: 700;
}
.f-24-400 {
  font-size: 24px;
  font-weight: 400;
}
.f-16-400 {
  font-size: 16px;
  font-weight: 400;
}
.f-16-600 {
  font-size: 16px;
  font-weight: 600;
}
.f-42-700 {
  font-size: 42px;
  font-weight: 700;
}
.f-40-600 {
  font-size: 40px;
  font-weight: 600;
}
.text-white {
  color: #fff;
}
.text-color-pre {
  color: #7ed957;
}
.text-color1-pre {
  color: rgba(255, 255, 255, 0.6);
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-76 {
  margin-bottom: 76px;
}
.w-50 {
  width: 50%;
}
.w-100 {
  width: 100%;
}
.pr-3 {
  padding-right: 16px;
}
.mb-1 {
  margin-bottom: 4px;
}
.mb-3 {
  margin-bottom: 16px;
}
input:focus,
input:hover {
  outline: none;
  border: none;
}
.pre-sign-btn {
  border-radius: 10px;
  background: #7ed957;
  color: #050505;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  border: none;
  height: 54px;
}
.cursor-pointer {
  cursor: pointer;
}
.mb-5 {
  margin-bottom: 32px;
}
.text-center {
  text-align: center;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.mh-100vh {
  min-height: 100vh;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: end;
}

.w-33-only {
  width: 33.33%;
}
.prev-btn {
  border: 1px solid #fff;
  height: 44px;
  font-size: 16px;
  color: #fff;
  background: transparent;
  padding: 0 16px;
  border-radius: 10px;
}
.next-btn {
  border: 1px solid #fff;
  height: 44px;
  font-size: 16px;
  color: #050505;
  background: #fff;
  padding: 0 16px;
  border-radius: 10px;
}
.position-relative {
  position: relative;
}
.mw-730 {
  max-width: 730px;
}

.nav-sectoin {
  display: block;
  position: sticky;
  top: 0;
  padding: 20px 0;
  background: #000;
  z-index: 1111;
}

.nav-sectoin .container {
  position: relative;
}

.nav-sectoin .nav-bar.active .menu-list-container {
  position: absolute;
  top: 100%;
  background: var(--primary-bg-color);
  width: 100%;
  left: 0;
}

.nav-sectoin .nav-bar.active .menu-list {
  width: 100%;
  padding: 16px;
  display: block;
  border-bottom: 1.5px solid var(--boder-color);
}

.nav-sectoin .nav-bar.active .menu-list .list {
  width: 100%;
  padding: 12px 0;
  border-top: 0.5px solid var(--boder-color);
}

.nav-sectoin .nav-bar .menu-list .list {
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  color: var(--color-white);
  margin-right: clamp(35px, 3.64vw, 70px);
  transition-duration: 0.3s;
  font-size: clamp(14px, 1.04vw, 20px);
}

.nav-sectoin .nav-bar .menu-list .list.active,
.nav-sectoin .nav-bar .menu-list .list:hover {
  color: var(--hover-color);
  cursor: pointer;
  transition-duration: 0.3s;
}

.nav-sectoin .nav-bar .menu-list .list:last-child {
  margin-right: 0;
}

.banner-wrapper .left-wrapper {
  width: 46.39%;
  position: relative;
}

.banner-wrapper .left-wrapper .confidential {
  position: absolute;
  bottom: 28.48%;
  left: 15.1%;
  width: 69.65%;
}

.banner-wrapper .heading {
  // max-width: 53.44%;
  margin-right: auto;
  width: 100%;
}

// .banner-wrapper .heading h1 {
//   color: var(--color-white);
//   font-weight: 700;
//   font-size: clamp(48px, 3.85vw, 74px);
//   line-height: clamp(52px, 4.63vw, 89.56px);
//   margin-bottom: 10px;
// }

.banner-wrapper .heading h1 span {
  color: var(--primary-color);
}

.banner-wrapper .heading p {
  max-width: 755px;
  margin: 0 auto 40px auto;
  font-weight: 400;
  color: var(--color-white);
  font-size: 20px;
  line-height: 29px;
}

.banner-wrapper .heading .heading-video {
  // width: 75%;
  margin: 0 auto 34px auto;
}

.banner-wrapper .heading .heading-video iframe {
  // width: 100%;
  border: 5px solid #fff;
  border-radius: 6px;
}

.about-us-section {
  background: var(--secondery-bg-color);
}

.about-us-section .about-us-box {
  width: 50%;
}

.about-us-section .about-us-left {
  max-width: 746px;
  margin: 0 auto;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.about-us-section .about-us-right .about-us-righ-content {
  max-width: 660px;
}

.about-us-section .about-us-right .about-us-righ-content h2 {
  margin-bottom: 18px;
}

.about-us-section .about-us-right .about-us-righ-content p {
  font-weight: 400;
  margin-bottom: 80px;
  font-size: clamp(14px, 1.04vw, 24px);
  line-height: clamp(28px, 1.97vw, 36px);
}

.portfolio-section .heading {
  margin-bottom: 90px;
}

.portfolio-section .heading .heading-right {
  max-width: 640px;
}

.portfolio-section .heading .heading-right p {
  font-weight: 400;
  text-align: right;
  font-size: clamp(18px, 1.04vw, 24px);
  line-height: clamp(28px, 1.97vw, 30px);
}

.portfolio-section .portfolio-wrapper .wrapper-content {
  margin: 0 -69px;
}

.portfolio-section .portfolio-wrapper .wrapper-content .content-box {
  width: calc(50% - 138px);
  margin: 0 69px 53px;
}

.portfolio-section .portfolio-wrapper .wrapper-content .content-box:nth-of-type(even) {
  margin-top: 64px;
}

.portfolio-section .portfolio-wrapper .wrapper-content .content-box:nth-last-of-type(-n + 2) {
  margin-bottom: 0;
}

.portfolio-section .portfolio-wrapper .wrapper-content .content-box .content-box-bottom {
  padding-left: 24px;
  margin-top: 24px;
}

.portfolio-section .portfolio-wrapper .wrapper-content .content-box .content-box-bottom h3 {
  font-weight: 500;
  color: var(--color-white);
  margin-bottom: 20px;
  font-size: clamp(18px, 1.04vw, 24px);
  line-height: clamp(28px, 1.97vw, 36px);
}

.portfolio-section .portfolio-wrapper .wrapper-content .content-box div img {
  width: 100%;
}

.footer-section .footer-wrapper {
  padding: 46px 0;
  border-top: 1.2px solid var(--boder-color);
  text-align: center;
}
.footer-section .footer-wrapper p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26.4px;
}
.pre-signup-right .pre-signup-right-inner {
  max-width: 694px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
}
.pre-input {
  border-radius: 10px;
  background: #191919;
  /*	backdrop-filter: blur(16px);*/
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  height: 44px;
  width: 100%;
  padding-left: 10px;
  border: none;
}

.pre-signup-right .pre-signup-question-inner {
  max-width: 1034px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
}
// .cong-border:before {
//   content: '';
//   width: 78px;
//   height: 2px;
//   background: #7ed957;
//   position: absolute;
//   top: 42px;
//   left: 0;
// }
.pre-signup-question-inner .invalid-feedback {
  display: block;
}

.primary-btn:hover {
  color: var(--primary-bg-color);
}
.ques-video video {
  border: 5px solid #fff;
  border-radius: 6px;
}

.ques-video iframe {
  border: 5px solid #fff;
  border-radius: 6px;
  background: #000;
}
.text-underline {
  text-decoration: underline;
}
select.pre-input {
  background-image: url('../../../assets/images/selec-down-white.svg');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
}
@media screen and (max-width: 1822px) {
  .portfolio-section .portfolio-wrapper .wrapper-content {
    margin: 0 -34.5px;
  }

  .portfolio-section .portfolio-wrapper .wrapper-content .content-box {
    width: calc(50% - 69px);
    margin: 0 34.5px 53px;
  }
}

@media screen and (max-width: 1753px) {
  .portfolio-section .portfolio-wrapper .wrapper-content {
    margin: 0 -15px;
  }

  .portfolio-section .portfolio-wrapper .wrapper-content .content-box {
    width: calc(50% - 34.5px);
    margin: 0 15px 53px;
  }
}

@media screen and (max-width: 1024px) {
  .portfolio-section .heading .heading-right,
  .about-us-section .about-us-right .about-us-righ-content {
    max-width: 100%;
  }
  .portfolio-section .heading .heading-right p {
    margin-top: 16px;
  }
}
@media screen and (max-width: 991px) {
  .banner-wrapper .heading {
    margin: 0 auto;
  }

  .about-us-section .about-us-box,
  .about-us-section .about-us-right .about-us-righ-content {
    width: 100%;
    margin-bottom: 16px;
  }
  .nav-sectoin {
    padding: 20px 0;
  }
  .banner-wrapper .left-wrapper {
    display: none;
  }
  .banner-wrapper .heading {
    max-width: 100%;
  }
  .banner-wrapper {
    background: none;
  }
  // .banner-wrapper {
  //   background: url(/assets/images/bg/main-bg.png) no-repeat;
  //   background: url(../images/bg/main-bg.png) no-repeat;
  //   background-size: contain;
  //   background-position: center;
  //   padding: 32px 0;
  // }
  .footer-section .footer-wrapper {
    padding: 16px 0;
  }
  .p5 {
    padding-top: 47px;
    padding-bottom: 47px;
  }
}
@media screen and (max-width: 767px) {
  .menu-btn {
    display: none;
  }
  .nav-sectoin .nav-bar.active .menu-list-container {
    position: unset;
    width: unset;
  }
  .nav-sectoin .nav-bar.active .menu-list {
    display: flex;
    position: unset;
    width: unset;
    padding: unset;
    border: none;
  }
  .nav-sectoin .nav-bar.active .menu-list .list {
    width: unset;
    padding: unset;
    border: none;
  }
  .nav-sectoin .nav-bar.active .menu-list .list {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 767px) {
  .p5 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .pl-2 {
    padding-left: 0px;
  }

  .portfolio-section .portfolio-wrapper .wrapper-content {
    margin: 0;
  }

  .nav-sectoin .nav-bar .menu-list {
    display: none;
  }

  .nav-sectoin {
    padding: 16px 0;
  }
  .banner-wrapper .heading h1 span {
    display: inline-block;
  }
  .about-us-section .about-us-right .about-us-righ-content p {
    margin-bottom: 48px;
  }
  .banner-wrapper {
    background-size: cover;
  }
  .portfolio-section .heading .heading-right p {
    text-align: left;
  }
  .footer-section .footer-wrapper p {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 991px) {
  .w-100-991 {
    width: 100% !important;
  }
  .d-none-991 {
    display: none !important;
  }
  .bg-img-991 {
    // background: url('../../.');
    background: url(/assets/images/bg/team-davis.png) no-repeat;
    background-size: 100%;
    background-position: bottom;
  }
}
@media (max-width: 767px) {
  .f-25-767 {
    font-size: 25px;
  }
  .cong-border:before {
    display: none;
  }
  .mb-30-767 {
    margin-bottom: 30px;
  }
  .w-100-767 {
    width: 100% !important;
  }
  .pr-0-767 {
    padding-right: 0;
  }
  .pl-0-767 {
    padding-left: 0;
  }
  .mt-5-767 {
    margin-top: 48px;
  }
  .mb-5-767 {
    margin-bottom: 48px;
  }
}
