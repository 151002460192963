@media (max-width: 1440px) {
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top {
    width: 100%;
  }
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-bottom {
    width: 100%;
  }
}
@media (max-width: 1366px) {
  .new-ass-que-wrapper {
    .new-ass-que-left {
      width: @full;
      margin-bottom: @twenty;
    }

    .new-ass-que-right {
      width: @full;
      padding-left: 0;
    }
  }
  .agent-sign-up-wrapper {
    .agent-sign-up-inner {
      .agent-sign-up-right {
        padding: 40px;
      }
    }
  }
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left {
    margin-bottom: 0;
  }
}
@media (max-width: 1200px) {
  .agent-sign-up-wrapper {
    .agent-sign-up-inner {
      .agent-sign-up-right {
        .profit-advisor-top {
          .profit-advisor-left {
            width: 100%;
            margin-bottom: 20px;
            padding-right: 0;
          }

          .profit-advisor-right {
            width: 100%;
            padding-left: 0;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .main-wrapper {
    .assessment-bottom {
      .assesment-table {
        width: @full;
        overflow: auto;

        .assesment-table-content {
          height: ~'calc(100vh - 430px)';
        }
      }

      .assesment-table-inner {
        width: 1200px;
      }

      .assement-search {
        // width: @full;
        // margin-bottom: @twenty - 10;
        width: calc(100% - 100px);
        margin-bottom: 0;
        padding-right: 10px;
      }

      .profit-filter {
        width: @full;
        margin: 0;
        margin-bottom: @twenty - 10;
      }

      .assement-select {
        padding-left: 0;
      }

      .assesment-button {
        width: 90px;
        text-align: right;
      }
    }
  }

  .new-user-detail-wrapper {
    .new-user-detail-left {
      width: @full;
      padding-right: 0;
      border-right: none;
      margin-bottom: @twenty;

      a {
        word-break: break-all;
      }
    }

    .new-user-detail-right {
      width: 100%;
      padding-left: 0;
    }
  }
}

@media (max-width: 991px) {
  .main-wrapper {
    .assessment-bottom {
      padding: @twenty;
    }
  }

  .question-list-wrapper {
    .question-inner {
      .question-list-main {
        .question-list {
          .question-list-content-wrapper {
            .question-list-content {
              width: ~'calc(100% - 40px)';
            }
          }
        }
      }
    }
  }

  .new-ass-que-wrapper {
    .new-ass-que-left {
      .new-ass-que-left-content {
        ul {
          li {
            &.ass-questions {
              width: ~'calc(100% - 30px)';
            }

            &.hint {
              margin-right: 0;
            }

            &.answers {
              width: ~'calc(100% - 80px)';
              margin-top: @twenty;
            }

            &.percentage {
              margin-top: @twenty;
            }
          }
        }
      }
    }
  }

  .model-popup-wrapper {
    .model-popup-inner {
      width: @full;
    }

    .model-popup {
      width: ~'calc(100% - 30px)';
      margin: 0 auto;
    }
  }

  .agent-sign-up-wrapper {
    height: auto;

    &:after,
    &:before {
      background-size: 175px;
      height: 175px;
      width: 175px;
    }

    .agent-sign-up-inner {
      .agent-sign-up-left {
        width: 100%;
        margin: 40px 0;

        .agent-sign-up-left-bottom {
          img,
          iframe {
            height: 340px;
          }
        }

        .agent-sign-up-left-top {
          margin-bottom: 40px;
        }
      }

      .agent-sign-up-right {
        width: 100%;
        margin: 0 0 50px 0;
      }
    }
  }
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.answers,
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage {
    margin-top: 0;
  }
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top .graph-wrapper {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .form-control-main .tooltip-inner {
    width: 360px;
  }
}

@media (max-width: 767px) {
  .sign-up-wrapper {
    min-height: 100vh;

    .sign-up-left,
    .sign-up-right {
      width: 100% !important;
    }

    .sign-up-left {
      padding: 20px 15px;
      display: none;
    }

    .sign-up-right {
      .sign-up-right-wrapper {
        padding: 20px 15px;

        .mobile-logo {
          display: block;
          margin-bottom: 30px;

          img {
            width: 100px;
          }
        }
      }
    }
  }

  .main-heading {
    font-size: 35px;
    line-height: 38px;
    margin-bottom: 40px;
  }

  .form-control-main {
    &.sign-in-btn {
      margin: 30px 0;
    }
  }
  //ASSESSMENT CSS START
  body .main-wrapper {
    .assessment-inner {
      .navigation {
        display: none;
        padding: 20px 15px;
        width: @full;
        position: absolute;
        cursor: pointer;
        top: 64px;
        border-top: 2px solid @bg-white;
        left: 0;
        width: 100%;
        z-index: 1;

        .navigation-logo {
          display: none;
        }

        nav {
          .mobile-close {
            display: block;
            position: absolute;
            top: 10px;
            right: 10px;
          }
        }
      }

      .assessment-body {
        width: @full;
      }

      &.menu-open {
        position: relative;

        .navigation {
          display: block;
        }
      }
    }

    .assessment-topbar {
      background: #000;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 12px 15px;

      .mobile-header {
        display: block;

        a {
          display: inline-block;
        }

        .mobile-logo {
          margin-left: 15px;

          img {
            height: 40px;
          }
        }
      }

      .userinfo {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .dropdown-toggle {
          span {
            white-space: nowrap;
            width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            text-align: left;
          }
        }
      }
    }

    .assessment-bottom {
      padding: 20px 15px;

      .assement-select {
        width: ~'calc(100% - 90px)';
      }

      .assesment-button {
        width: 90px;
        margin-left: 0 !important;
      }

      .assesment-table-inner {
        width: unset;
      }

      .assesment-table {
        padding: 0;

        .assesment-table-header {
          display: none;
        }

        .assesment-table-content {
          height: unset;

          ul {
            &.table-main-content {
              padding: 10px 22px 10px 10px;
              position: relative;
            }
          }

          .assessment-list {
            .assessment-list-top {
              padding: 10px;

              .top-assessments,
              .top-created-date,
              .top-updated-date {
                display: none;
              }
            }

            .assessment-list-bottom {
              ul {
                padding-left: 10px;

                li {
                  &:before {
                    display: inline-block;
                    font-weight: 600;
                    margin-right: 10px;
                  }
                }

                .bot-new-name {
                  &:before {
                    content: 'Assessments : ';
                  }
                }

                .bot-created-date {
                  &:before {
                    content: 'Created Date : ';
                  }
                }

                .bot-updated-date {
                  &:before {
                    content: 'Updated Date : ';
                  }
                }

                .bot-new-edit {
                  &:before {
                    content: 'Edit :';
                  }
                }

                .bot-new-download {
                  &:before {
                    content: 'Download :';
                  }
                }

                .bot-new-delete {
                  &:before {
                    content: 'Delete :';
                  }
                }
              }
            }
          }
        }

        ul {
          li {
            width: @full !important;
            padding: 5px 0 !important;

            &:before {
              display: inline-block;
              font-weight: 600;
              margin-right: 10px;
            }

            &.company-name {
              &:before {
                content: 'Loan Officer Name : ';
              }
            }

            &.email {
              &:before {
                content: 'Email : ';
              }
            }

            &.phone-number {
              &:before {
                content: 'phone-number : ';
              }
            }

            &.sign-up-date {
              &:before {
                content: 'Sign Up Date : ';
              }
            }

            &.event-end {
              &:before {
                content: 'Event End : ';
              }
            }

            &.edit {
              text-align: left;

              &:before {
                content: 'Edit : ';
              }
            }

            &.reset {
              text-align: left !important;

              &:before {
                content: 'Reset : ';
              }
            }

            &.new-action {
              text-align: left !important;

              &:before {
                content: 'Show Agent : ';
              }

              .showAgent-btn {
                display: inline-block;
              }
            }

            &.event-start {
              &:before {
                content: 'Event Start : ';
              }
            }

            &.assessment {
              text-align: left;

              &:before {
                content: 'Assessment : ';
              }
            }

            &.delete {
              text-align: left;

              &:before {
                content: 'Action : ';
              }
            }

            &.action {
              position: absolute;
              top: 10px;
              right: 5px;
              width: auto !important;
            }
          }
        }
      }

      .question-header {
        .add-new {
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    .custom-radio {
      width: @full;
      padding-right: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .question-bottom-wrapper {
    .question-bottom-inner {
      form {
        .from-group {
          width: 100%;
        }
      }
    }
  }

  .new-user-detail-wrapper {
    padding: @twenty - 5;

    .new-user-detail-left {
      .new-user-left-user {
        width: @full;
        margin-bottom: @twenty;
      }

      .new-user-left-content {
        width: @full;
      }
    }

    .new-user-detail-right {
      .new-user-right-current {
        width: @full;
      }
    }
  }

  .new-ass-que-wrapper {
    .new-ass-que-left {
      .new-ass-que-left-content {
        ul {
          padding: 0 15px;

          li {
            &.answers {
              padding-right: 10px;
              width: @full;
            }

            &.percentage {
              width: @full;

              select {
                margin: 0 10px 0 0;
              }
            }
          }
        }
      }
    }
  }

  .model-popup-wrapper {
    .model-popup {
      padding: @twenty;
    }
  }

  .agent-sign-up-wrapper {
    &:after,
    &:before {
      background-size: 100px;
      height: 100px;
      width: 100px;
    }

    .agent-sign-up-inner {
      .agent-sign-up-left {
        .agent-sign-up-left-top {
          margin-bottom: 20px;

          img {
            max-width: 150px;
          }
        }

        .agent-sign-up-left-bottom {
          img,
          iframe {
            height: 250px;
          }
        }
      }

      .agent-sign-up-right {
        padding: 20px;

        h2 {
          margin-bottom: 20px;
        }

        .profit-advisor-top {
          p {
            //width: 100%;
            //margin-bottom: 16px;
          }
        }

        form {
          .form-control-main {
            width: calc(100% - 24px);

            &.profit-signup {
              p {
                width: 100%;
                margin-bottom: 16px;
              }
            }
          }
        }
      }
    }
  }
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper
    .new-ass-que-left
    .new-ass-que-left-content
    .new-ass-que-left-content-content
    ul
    li.answers
    button {
    width: calc(33.33% - 10px);
    margin-right: 10px;
  }
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper
    .new-ass-que-left
    .new-ass-que-left-content
    .new-ass-que-left-content-content
    ul
    li.answers
    button:last-child {
    width: 33.33%;
    margin-right: 0;
  }
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper
    .new-ass-que-left
    .new-ass-que-left-content
    .new-ass-que-left-content-content
    ul
    li.answers
    .w-25 {
    width: 100% !important;
    margin-top: 16px;
  }
}

@media (max-width: 767px) {
  //.new-ass-que-wrapper .new-ass-que-right{
  //  position: absolute;
  //  top: 78px;
  //  left: 0;
  //  padding: 0 15px;
  //}

  .new-ass-que-wrapper .user-input-div-details {
    width: 100%;
  }

  .new-ass-que-wrapper .new-ass-que-left {
    //padding-top: 10px;
  }

  .new-ass-que-wrapper .user-details-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #231f20;
    color: #ffffff;
    padding: 16px;
  }
  h1 {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0;
  }
  .new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top {
    padding: 0;
  }
  .new-ass-que-wrapper .new-user-left-content {
    padding: 16px;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left {
    margin-top: 20px;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left h2 {
    font-size: 17px;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li.hint,
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li.answers,
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li.percentage {
    display: none;
  }
  .graph-container .graph-block {
    margin: 0;
  }
  .graph-container .Graph-box {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left + .new-ass-que-right .user-detail-form {
    display: none;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-inner > .user-details-header {
    padding: 0;
  }
  .new-assessment-bottom
    .new-ass-que-wrapper
    .new-ass-que-right
    .user-detail-form.active-form
    .new-ass-que-right-top
    .new-user-left-content,
  .new-assessment-bottom
    .new-ass-que-wrapper
    .new-ass-que-right
    .user-detail-form.active-form
    .new-ass-que-right-top
    .user-details-container {
    display: block;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-right .user-detail-form .new-ass-que-right-top .new-user-left-content,
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-right .user-detail-form .new-ass-que-right-top .user-details-container {
    display: none;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .landing-signup-container .signup-img iframe {
    width: 100%;
  }
  .signup-input-div.phoneInput .iti--allow-dropdown {
    width: 100%;
  }
  .signup-input-div.phoneInput .iti--allow-dropdown .agentLandingPhoneInput {
    width: 100%;
  }
  .signup-title h2 {
    font-size: 26px;
    line-height: 38px;
  }
  .question-list-wrapper .question-inner .question-list-main .question-list .question-list-head h3.word-break {
    width: ~'calc(100% - 205px)';
  }
  .question-list-wrapper .question-inner .question-list-main .question-list .question-list-head h3 ~ .d-flex {
    width: 205px;
  }
  .banner-wrapper .heading .heading-video iframe {
    height: auto;
  }
  .banner-wrapper .heading h2 {
    font-size: 22px;
  }
}
