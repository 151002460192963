//Common Css
* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: normal;
}

body {
  margin-bottom: 0;
  color: @text-black;
}

a,
a:hover {
  text-decoration: none;
  color: #ffffff;
}

img {
  max-width: @full;
}

p,
ul,
li {
  margin: 0;
  padding: 0;
}

input,
textarea,
.form-control,
select {
  &:hover,
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

//Variable Start
@bg-white: #ffffff;
@bg-black: #231f20;
@button-bg: #fa7a23;
@input-bg: #3c3939;
@text-white: #ffffff;
@input-text-color: #747474;
@orange: #fa7a23;
@text-black: #231f20;
@border-color: #231f20;
@border-color1: #dad9dc;
@blue-bg: #2c5c84;

@twenty: 20px;
@thirty: 30px;
@fifty: 50px;
@full: 100%;
@half: 50%;
@none: none;

//Common CSS
::-webkit-scrollbar {
  width: 5px;
  background: #e4e1df;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #a2a2a2;
  width: 5px;
  border-radius: 10px;
}

.main-heading {
  font-size: 44px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 70px;
}

.sign-in-btn {
  button {
    width: 180px;
    height: @fifty;
    line-height: @fifty;
    background: @button-bg;
    border: 1px solid @button-bg;
    color: @text-white;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    transition: 0.5s;

    &:hover {
      background: transparent;
      color: @orange;
    }
  }
}

.form-control-main {
  margin-bottom: @twenty + 4;
  width: @full;
  display: inline-block;

  span {
    position: absolute;
    top: 14px;
    left: 15px;
  }

  .form-control-main1 {
    margin-bottom: @twenty + 4;
    width: @full;
    display: inline-block;
  }

  .phoneInputCustom {
    border-radius: 0;
    height: 50px;
    line-height: 50px;
    background: #ebebeb !important;
    border: none;
    border: none;
    padding-left: 70px !important;
    color: #040404;
    max-width: 95%;
  }

  .phoneInputCustom:hover,
  .phoneInputCustom:focus {
    box-shadow: none;
  }

  .phoneInputCustom::-webkit-input-placeholder {
    color: #747474;
    font-size: 14px;
  }

  .phoneInputCustom:-ms-input-placeholder {
    color: 747474;
    font-size: 14px;
  }

  .phoneInputCustom::placeholder {
    color: 747474;
    font-size: 14px;
  }

  .custom {
    border-radius: 0;
    height: 50px;
    line-height: 50px;
    background: blue !important;
    border: none;
    border: @none;
    padding-left: 45px;
    color: @text-white;

    &:hover,
    &:focus {
      box-shadow: @none;
    }

    &::-webkit-input-placeholder {
      color: @input-text-color;
      font-size: 14px;
    }

    &:-ms-input-placeholder {
      color: @input-text-color;
      font-size: 14px;
    }

    &::placeholder {
      color: @input-text-color;
      font-size: 14px;
    }
  }

  .form-control {
    border-radius: 0;
    height: 50px;
    line-height: 50px;
    background: @input-bg;
    border: @none;
    padding-left: 45px;
    color: @text-white;

    &:hover,
    &:focus {
      box-shadow: @none;
    }

    &::-webkit-input-placeholder {
      color: @input-text-color;
      font-size: 14px;
    }

    &:-ms-input-placeholder {
      color: @input-text-color;
      font-size: 14px;
    }

    &::placeholder {
      color: @input-text-color;
      font-size: 14px;
    }
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .forget-password {
    color: @orange;
    font-size: 16px;
    font-weight: 500;
  }

  &.sign-in-btn {
    margin: @fifty 0;
  }
}

//CSS START
.sign-up-wrapper {
  background: @bg-black;
  min-height: 100vh;

  .sign-up-left {
    background: @bg-white;
    padding-top: @fifty - 20px;

    .bottom-img {
      padding-top: @fifty;
    }

    .signup-text {
      margin: @fifty 0 0 0;

      h2 {
        font-size: 26px;
        font-weight: 700;
        line-height: 31px;
        margin-bottom: @twenty;
      }

      p {
        line-height: @twenty + 4;
        max-width: 50%;
        margin: 0 auto;
      }
    }
  }

  .sign-up-right {
    color: @text-white;

    .sign-up-right-wrapper {
      max-width: 530px;
      margin: 0 auto;
      padding: 0 15px;

      .mobile-logo {
        display: none;
      }
    }

    p {
      font-size: 18px;

      a {
        font-weight: 600;
        color: @text-white;
        font-size: 18px;
        transition: 0.5s;

        &:hover {
          color: @orange;
        }
      }
    }
  }
}

//Assessment Start
//.main-wrapper {
//  .assessment-inner {
//      background: @bg-white;
//      display: flex;
//      flex-wrap: wrap;
//      min-height: 100vh;
//      width: 100%;

//      .navigation {
//          background: @bg-black;
//          width: 240px;
//          color: @text-white;
//          padding: 10px 30px;

//          .navigation-logo {
//              padding-bottom: @fifty;
//          }

//          nav {
//              ul {
//                  li {
//                      a {
//                          display: flex;
//                          flex-wrap: wrap;
//                          align-items: center;
//                          padding-bottom: @twenty - 5;

//                          span {
//                              font-weight: 700;
//                              color: @text-white;
//                              padding-left: @twenty - 5;
//                          }
//                      }
//                  }
//              }
//          }
//      }

//      .assessment-body {
//          width: ~"calc(100% - 240px)";
//      }
//  }

//  .assessment-topbar {
//      background: @orange;
//      padding: 12px @twenty + 10;
//      .userinfo {
//          font-weight: 600;
//          color: @text-white;
//          span {
//              padding-left: 10px;
//          }
//          img {
//              border-radius: 8px;
//          }
//      }
//  }
//}

.main-wrapper {
  .assessment-inner {
    background: @bg-white;
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    width: 100%;

    .navigation {
      background: #000;
      width: 240px;
      color: @text-white;
      padding: 10px;
      cursor: pointer;

      .navigation-logo {
        padding-bottom: @fifty;
      }

      nav {
        .mobile-close {
          display: none;
        }

        ul {
          li {
            a {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              margin-bottom: @twenty + 5;

              span {
                //font-weight: 700;
                color: @text-white;
                padding-left: @twenty - 5;
                width: calc(100% - 35px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }

    .assessment-body {
      width: ~'calc(100% - 240px)';
    }
  }

  .assessment-topbar {
    background: #000;
    padding: 12px @twenty + 10;

    .mobile-header {
      display: @none;
    }

    .userinfo {
      font-weight: 600;
      color: @text-white;

      img {
        width: 40px;
        height: 40px;
      }

      .dropdown-toggle {
        padding: 0;
        border: none;
        color: @text-white;
        font-size: 14px;
        padding-left: 15px;
        background: transparent;

        &:hover,
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      img {
        border-radius: 8px;
      }
    }
  }

  .assessment-bottom {
    padding: @thirty;

    .page-title {
      font-size: 26px;
      font-weight: 700;
      line-height: 31px;
      margin-bottom: @twenty;

      &.new-question-title {
        margin-bottom: 0;
      }
    }

    .assement-header-bottom {
      margin-bottom: 12px;
    }

    .assement-select {
      padding: 0 13px 0 60px;
      width: 357px;
    }

    .assement-search {
      width: ~'calc(100% - 447px)';

      input {
        border: 1px solid @border-color;
        width: 100%;
        height: 42px;
        padding-left: @twenty;
        color: @text-black;
        transition: 0.5s;

        &:hover,
        &:focus {
          box-shadow: none;
          outline: none;
          border-color: @orange;
        }

        &::-webkit-input-placeholder {
          color: @border-color1;
          font-size: 14px;
        }

        &:-ms-input-placeholder {
          color: @border-color1;
          font-size: 14px;
        }

        &::placeholder {
          color: @border-color1;
          font-size: 14px;
        }
      }
    }

    .profit-filter {
      width: auto;
      flex-grow: 1;
      margin: 1rem;

      select {
        border: 1px solid @border-color;
        width: 100%;
        height: 42px;
        padding-left: @twenty;
        color: @text-black;
        transition: 0.5s;

        &:hover,
        &:focus {
          box-shadow: none;
          outline: none;
          border-color: @orange;
        }

        &::-webkit-input-placeholder {
          color: @border-color1;
          font-size: 14px;
        }

        &:-ms-input-placeholder {
          color: @border-color1;
          font-size: 14px;
        }

        &::placeholder {
          color: @border-color1;
          font-size: 14px;
        }
      }
    }

    .assesment-company {
      background: @blue-bg;
      padding: 16px;

      p {
        font-size: 18px;
        font-weight: 600;
        color: @text-white;
      }

      a {
        font-weight: 600;
        color: @text-white;
        text-transform: capitalize;

        span {
          padding-left: 10px;
        }
      }
    }

    .assesment-table {
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
      padding: 17px;
      //height: calc(100vh - 296px);
      //overflow: auto;

      .assesment-table-header {
        background: @bg-black;
        color: @text-white;
        padding: 13px 25px;
      }

      ul {
        li {
          word-break: break-all;

          &.company-name {
            width: 16%;
            padding: 0 5px;
          }

          &.email {
            width: 13%;
            padding: 0 5px;
          }

          &.phone-number {
            width: 11%;
            padding: 0 5px;
          }

          &.sign-up-date {
            width: 10%;
            padding: 0 5px;
          }

          &.event-start {
            width: 13%;
            padding: 0 5px;
          }

          &.event-end {
            width: 13%;
            padding: 0 5px;
          }

          &.assessment {
            width: 6%;
            text-align: center;
            padding: 0 5px;
          }

          &.edit {
            width: 5%;
            text-align: center;
            padding: 0 5px;
          }

          &.reset {
            width: 5%;
            text-align: center;
            padding: 0 5px;
          }

          &.delete {
            width: 5%;
            text-align: center;
            padding: 0 5px;
          }

          &.action {
            width: 3%;
            text-align: right;
            padding: 0 5px;
          }
        }
      }

      .assesment-table-content {
        border: 1px solid @border-color1;
        height: ~'calc(100vh - 377px)';
        overflow: auto;

        ul.table-main-content {
          padding: 20px 25px;
          cursor: pointer;
          border-bottom: 1px solid @border-color1;

          li {
          }
        }

        .assessment-list {
          background: rgba(35, 31, 32, 0.06);
          width: 100%;
          display: inline-block;
          border-bottom: 1px solid @border-color1;

          .assessment-list-top {
            padding: 12px 25px 12px 100px;

            .assessment-list-top-ul {
              li {
                font-weight: 600;
              }

              .top-assessments {
                width: 23%;
                padding: 0 5px;
              }

              .top-created-date {
                width: 23%;
                padding: 0 5px;
              }

              .top-updated-date {
                width: 34%;
                padding: 0 5px;
              }

              .top-ass-btn {
                width: 20%;
                padding: 0 5px;
                text-align: right;
              }
            }

            a {
              height: 32px;
              line-height: 32px;
              background: @orange;
              display: inline-block;
              width: 173px;
              text-align: center;

              span {
                font-size: 12px;
                color: @text-white;
                padding-left: 10px;
              }
            }
          }

          .assessment-list-bottom {
            border-top: 1px dashed #a2a2a2;

            ul {
              padding: 20px 25px 20px 100px;

              .bot-new-name {
                width: 23%;
                padding: 0 5px;
              }

              .bot-created-date {
                width: 23%;
                padding: 0 5px;
              }

              .bot-updated-date {
                width: 34%;
                padding: 0 5px;
              }

              .bot-new-edit {
                width: 6%;
                padding: 0 5px;
              }

              .bot-new-download {
                width: 5%;
                padding: 0 5px;
              }

              .bot-new-delete {
                width: 8%;
                padding: 0 5px 0 23px;
              }
            }
          }
        }
      }
    }

    .question-header {
      .add-new {
        height: 40px;
        width: 160px;
        background: @orange;
        border: none;
        font-size: 16px;
        font-weight: 500;
        color: @text-white;
        text-transform: capitalize;
        margin-left: 15px;

        img {
          margin-left: 5px;
        }
      }
    }
  }

  .custom-radio {
    padding-right: 40px;

    .custom-radio-container {
      display: block;
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin-bottom: 0;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked {
          ~ .checkmark {
            &:after {
              display: block;
            }
          }
        }
      }

      .checkmark {
        &:after {
          top: 3px;
          left: 3px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: @orange;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border-radius: 50%;
      border: 2px solid @orange;

      &:after {
        content: '';
        position: absolute;
        display: none;
      }
    }
  }

  .assesment-button {
    width: 90px;

    a {
      width: 90px;
      height: 42px;
      line-height: 42px;
      background: @orange;
      color: @text-white;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      display: inline-block;
    }
  }
}

//New Assessment start
.new-user-detail-wrapper {
  color: @text-white;
  background: @bg-black;
  padding: @twenty - 2 @twenty + 4;
  margin-bottom: @twenty + 4;

  a {
    color: @text-white;
  }

  .new-user-detail-left {
    width: @half;
    border-right: 1px solid #dad9dc;
    padding-right: @fifty;

    .new-user-left-user {
      width: @half;

      p {
        font-size: 22px;
        line-height: 26px;
        font-weight: 700;
        text-transform: capitalize;
      }
    }

    .new-user-left-content {
      width: @half;

      ul {
        li {
          width: @full;
          margin-bottom: 15px;

          p,
          span {
            width: @half;
            padding: 0 5px;
          }

          span {
            font-weight: 500;
          }
        }
      }
    }
  }

  .new-user-detail-right {
    width: @half;
    padding-left: @fifty;

    .new-user-right-current {
      width: @half;

      ul {
        li {
          width: @full;
          margin-bottom: 15px;

          p {
            width: @half;
            padding: 0 5px;
          }

          label {
            width: @half;
            padding: 0 5px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.new-ass-que-wrapper {
  .new-ass-que-left {
    width: 65%;
    max-height: 141vh;
    overflow-y: auto;
    overflow-x: unset;

    h2 {
      font-size: 26px;
      font-weight: 700;
      text-transform: capitalize;
      margin: 0 0 @twenty - 5 0;
    }

    .new-ass-que-left-content {
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
      margin-bottom: @twenty;

      ul {
        padding: 0 24px;

        li {
          &.ass-questions {
            width: ~'calc(100% - 434px)';
            padding-right: @thirty;
          }

          &.acc-arrow {
            width: 24px;
          }

          &.hint {
            width: ~'calc(60px - 30px)';
            margin-right: 30px;
          }

          &.answers {
            width: 260px;
            padding-right: 30px;
          }

          &.percentage {
            width: 90px;
          }

          &.tooltip-hint {
            position: relative;
            cursor: help;

            &:hover {
              .tool-tip {
                display: block;
              }
            }

            .tool-tip {
              position: absolute;
              background: #fff;
              padding: 10px;
              top: 0px;
              right: 38px;
              width: 250px;
              box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.1);
              border: 1px solid @border-color1;
              display: none;

              &:before {
                content: '';
                right: -9px;
                top: 3px;
                position: absolute;
                width: 0;
                height: 0;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-left: 8px solid @border-color1;
              }
            }
          }
        }
      }

      .new-ass-que-left-content-head {
        background: #dad9dc;
        color: #231f20;
        text-transform: capitalize;
        padding: @twenty - 6 0;

        ul {
          li {
            font-weight: 600;
          }
        }
      }

      .new-ass-que-left-content-content {
        ul {
          //border-bottom: 1px solid #DAD9DC;
          padding-top: @twenty - 10;
          padding-bottom: @twenty - 10;

          li {
            &.ass-questions {
              line-height: 16px;
            }

            &.answers {
              button {
                width: @fifty + 20;
                height: @thirty;
                line-height: @thirty;
                border: 1px solid @border-color;
                text-transform: capitalize;
                font-size: 12px;
                background: transparent;
                text-align: center;
                display: inline-block;
                color: @text-black;
                margin-right: 10px;

                &.active {
                  background: @bg-black;
                  color: @text-white;
                }

                &:nth-child(3) {
                  margin-right: 0;
                }
              }

              textarea {
                width: @full;
                border: 1px solid @border-color1;
                margin-top: 10px;
                padding: 8px;
                height: 52px;

                &::-webkit-input-placeholder {
                  /* Edge */
                  color: @border-color1;
                  font-size: 12px;
                }

                &:-ms-input-placeholder {
                  /* Internet Explorer */
                  color: @border-color1;
                  font-size: 12px;
                }

                &::placeholder {
                  color: @border-color1;
                  font-size: 12px;
                }
              }
            }

            &.percentage {
              input,
              select {
                width: @fifty + 40;
                height: @thirty;
                line-height: @thirty;
                border: 1px solid @border-color1;
                font-size: 12px;
                text-align: center;
                display: inline-block;
                color: @text-black;
              }

              select {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }

  .new-ass-que-right {
    width: 35%;
    padding-left: @twenty + 5;

    .new-ass-que-right-top {
      // box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
      padding: 16px;
      margin-bottom: 10px;
      background: #231f20;
      color: #ffffff;

      .bar-chart-wrapper {
        width: 50%;

        canvas {
          width: 100% !important;
          height: auto !important;
        }
      }

      .chart-container {
        max-width: 100%;
        overflow: auto;
      }
    }

    .new-ass-que-right-bottom {
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
      padding: @twenty + 4;
    }
  }

  .graph-block {
    width: 35%;
    padding-left: @twenty + 5;

    .Graph-box {
      // box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
      padding: 16px;
      margin-bottom: 10px;
      background: #231f20;
      color: #ffffff;

      .bar-chart-wrapper {
        width: 50%;

        canvas {
          width: 100% !important;
          height: auto !important;
        }
      }

      .chart-container {
        max-width: 100%;
        overflow: auto;
      }
    }

    .new-ass-que-right-bottom {
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
      padding: @twenty + 4;
    }
  }
}

//.add-new-question-btn {
//  height: 32px;
//  line-height: 32px;
//  background: @orange;
//  display: inline-block;
//  width: 155px;
//  text-align: center;

//  span {
//    font-size: 12px;
//    color: @text-white;
//    padding-left: 10px;
//  }
//}
.question-bottom-wrapper {
  //margin-top: @twenty;
  //box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  //padding: @twenty + 4 @twenty + 4 10px @twenty + 4;

  .question-bottom-inner {
    form {
      margin: 0 -25px;

      .from-group {
        width: 50%;
        padding: 0 25px;
        margin-bottom: 15px;

        input,
        select {
          height: 42px;
          border: 1px solid @border-color1;
          font-size: 15px;
          border-radius: 0;
        }

        select {
          //width: ~"calc(100% - 44px)";
          width: 100%;
        }

        .add-new {
          height: 42px;
          width: 44px;
          background: @orange;
          border: none;
        }

        .added-btn {
          height: 50px;
          width: 160px;
          background: #000;
          border: none;
          font-size: 16px;
          font-weight: 600;
          color: @text-white;
        }

        .switch-text {
          font-size: 15px;
          font-weight: 500;
        }

        .form-switch {
          margin-left: @thirty;
          padding-left: 0;

          input {
            height: 18px;
            width: 32px;
            border-radius: 10px;
            border: none;
            background-color: #ffd3b6;
          }

          .switch {
            position: relative;
            display: inline-block;
            width: 32px;
            height: 18px;

            input {
              opacity: 0;
              width: 0;
              height: 0;

              &:checked + .slider {
                background-color: #fa7a23;

                &:before {
                  -webkit-transform: translateX(14px);
                  -ms-transform: translateX(14px);
                  transform: translateX(14px);
                }
              }
            }
          }

          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: 0.4s;
            transition: 0.4s;

            &:before {
              position: absolute;
              content: '';
              height: 14px;
              width: 14px;
              left: 2px;
              bottom: 2px;
              background-color: white;
              -webkit-transition: 0.4s;
              transition: 0.4s;
            }

            &.round {
              border-radius: 34px;

              &:before {
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
}

.question-list-wrapper {
  margin-top: @twenty + 4;

  .question-inner {
    .question-title {
      margin-bottom: 15px;

      h2 {
        font-size: 26px;
        line-height: 31px;
        font-weight: 700;
        margin-bottom: 0;
      }
    }

    .question-list-main {
      .question-list {
        margin-bottom: 16px;

        .question-list-head {
          background: #ffffff;
          box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
          padding: 17px 20px;

          h3 {
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 0;
          }

          .up-arrow {
            display: none;
          }
        }

        &.active-question {
          .question-list-head {
            .up-arrow {
              display: block;
            }

            .down-arrow {
              display: none;
            }
          }
        }

        .question-list-content-wrapper {
          display: flex;
          flex-wrap: wrap;

          .question-list-content {
            width: ~'calc(50% - 40px)';
            margin: 0 @twenty;
            padding: @twenty + 5 0;
            border-bottom: 1px dashed @border-color1;

            &:last-child,
            &:nth-last-child(2) {
              border-bottom: none;
            }

            .question-main {
              margin-bottom: 16px;

              p {
                width: ~'calc(100% - 85px)';
                padding-right: 10px;
              }

              .question-action {
                width: 85px;

                .question-action-delete {
                  margin-left: @fifty;
                }
              }
            }

            .question-hint {
              margin-bottom: 16px;

              label {
                width: 52px;
                font-weight: 600;
              }

              p {
                width: ~'calc(100% - 52px)';
              }
            }

            .question-bottom {
              label {
                font-size: 12px;
                font-weight: 600;
              }

              p {
                padding-left: 10px;
              }

              .question-percentage {
                margin-right: @fifty;

                p {
                  font-weight: 700;
                  color: #2c5c84;
                }
              }

              .question-status {
                p {
                  font-weight: 700;
                  color: @orange;
                }
              }
            }
          }
        }

        &.active-question {
          .question-list-head {
            background: @blue-bg;
            color: @text-white;
          }

          .question-list-content-wrapper {
            border: 1px solid @blue-bg;
          }
        }
      }
    }
  }
}

//MODEL POPUP START
.model-popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .model-popup {
    width: 822px;
    background: @bg-white;
    padding: 34px;
    overflow-x: hidden;

    .model-popup-title {
      margin: 0 0 @thirty + 4 0;
      flex-wrap: wrap;
      align-items: center;

      h3 {
        font-size: 26px;
        font-weight: 700;
        text-transform: capitalize;
        margin-bottom: 0;
      }

      .btn-close {
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

    .model-popup-content {
      margin-bottom: @thirty;

      .from-group {
        input {
          height: 42px;
          border-radius: 0;
        }
      }
    }

    .model-popup-footer {
      .save-btn {
        width: 160px;
        height: 50px;
        line-height: 50px;
        background: @orange;
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        color: @text-white;
        text-align: center;
      }
    }
  }
}

.modal-backdrop {
  &.fade {
    &.show {
      transition: unset;
    }
  }
}

.d-block {
  &.fade {
    transition: unset;
  }
}

.modal {
  &.fade {
    .modal-dialog {
      transition: unset;
    }
  }
}

.pdf-component canvas {
  height: auto !important;
  width: 100% !important;
}

.form-control-main select.form-control {
  background: #3c3939 url('/assets/images/select-down-arrow.svg') no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: 45%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.company-name {
  width: 23%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.sign-up-date {
  width: 15%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.email {
  width: 18%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.phone-number {
  width: 16%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.assessment {
  width: 10%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.edit {
  width: 5%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.reset {
  width: 5%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.delete {
  width: 5%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.action {
  width: 3%;
}

.sign-up-left img:first-child {
  max-width: 333px;
}

.main-wrapper .assessment-inner .navigation .navigation-logo img {
  max-width: 117px;
}

//AGENT SIGN UP START
.agent-sign-up-wrapper {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;

  &:before {
    content: '';
    background: url('/assets/images/agent-bottom-bg.svg') no-repeat;
    width: 367px;
    height: 366px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    background: url('/assets/images/agent-top-bg.svg') no-repeat;
    width: 367px;
    height: 366px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .agent-sign-up-inner {
    max-width: 1680px;
    margin: 0 auto;
    padding: 0 20px;
    z-index: 1;

    .agent-sign-up-left {
      width: calc(50% - 40px);
      margin-right: 40px;

      .agent-sign-up-left-top {
        margin-bottom: 70px;
      }

      .agent-sign-up-left-bottom {
        img,
        iframe {
          width: 100%;
          height: 450px;
        }
      }
    }

    .agent-sign-up-right {
      width: calc(50% - 40px);
      margin-left: 40px;
      background: #ffffff;
      box-shadow: 0px 10px 100px rgba(0, 0, 0, 0.06);
      padding: 72px 72px 56px 72px;

      h2 {
        font-weight: 700;
        font-size: 34px;
        color: #231f20;
        margin-bottom: 60px;
      }

      .profit-advisor-top {
        margin-bottom: 16px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        padding: 12px;

        .profit-advisor-left {
          width: 50%;
          padding-right: 12px;
        }

        .profit-advisor-right {
          width: 50%;
          padding-left: 12px;

          ul {
            padding: 0;
            margin: 0;

            li {
              padding: 0;
              margin: 0;
              list-style: none;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 12px;

              &:last-child {
                margin-bottom: 0;
              }

              p,
              label {
                font-size: 14px;
                font-weight: normal;
                color: #231f20;
              }
            }
          }
        }

        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
        }

        label {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
        }
      }

      form {
        margin: 0 -12px;
        display: flex;
        flex-wrap: wrap;

        .form-control-main {
          width: calc(50% - 24px);
          margin: 0 12px 16px 12px;

          &.address-input {
            width: calc(100% - 24px);
          }

          &.profit-signup {
            p {
              font-size: 18px;

              a {
                font-weight: 600;
                font-size: 18px;
                color: #231f20;

                &:hover {
                  color: #fa7a23;
                  transition-duration: 0.5s;
                }
              }
            }
          }

          span {
            top: 10px;
          }

          .form-control {
            background: #ebebeb;
            color: #231f20;
            height: 42px;
            line-height: 35px;
          }

          &.phoneInput {
            .iti--allow-dropdown {
              width: 100%;

              .iti__flag-container {
                .iti__selected-flag.dropdown-toggle {
                  width: 100%;
                }
              }

              .country-dropdown {
                width: 230px;

                .iti__country-list {
                  li {
                    position: relative;

                    .iti__country-name {
                      left: 76px;
                      top: 6px;
                    }

                    .iti__dial-code {
                      left: 37px;
                      top: 6px;
                    }
                  }
                }
              }
            }

            .phoneInputCustom {
              width: 100%;
              max-width: 100%;
              height: 42px;
              line-height: 35px;
              padding-left: 85px !important;
            }
          }
        }
      }
    }
  }
}

//Account image set full screen
.height-sidebar-100 {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.scroll-sidebar-100 {
  height: 100vh;
  overflow: auto;
}

.phoneInput .agentLandingPhoneInput {
  height: 42px;
}

.signup-input-div.phoneInput .iti {
  width: 100%;
}

.signup-input-div.phoneInput .iti input {
  width: 100%;
}

.width-class-generator(@index) when (@index > 0) {
  @class-name: ~'w-@{index}';
  @class-namePX: ~'w-px-@{index}';

  .@{class-name} {
    width: ~'@{index}%' !important;
  }

  .@{class-namePX} {
    width: ~'@{index}px' !important;
  }

  .width-class-generator(@index - 1);
}

.width-class-generator(100);

.height-class-generator(@index) when (@index > -1) {
  @height-class-name: ~'h-@{index}';

  .@{height-class-name} {
    height: ~'@{index}%' !important;
  }

  .height-class-generator(@index - 1);
}

.height-class-generator(100);

#landingContent {
  line-height: 30px;
}

.exclamation_mark {
  border: 1px solid orange;
  border-radius: 50%;
  padding: 0.1em 0.4em;
  color: orange;
  max-height: 22px;
  display: flex;
  align-items: center;
  max-width: 22px;
  width: 22px;
  justify-content: center;
  margin-right: 0.4rem;
}

.word-break {
  word-break: break-all;
  word-break: break-word;
}

.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-bottom {
  margin-top: 15px;
  padding: 0 15px;
}

.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-bottom .next-btn {
  width: 120px;
  height: 46px;
  background: #fa7a23;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-bottom .prev-btn {
  width: 120px;
  height: 46px;
  background: #2c5c84;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content {
  padding-bottom: 15px;
}

.new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content.active-faq .new-ass-que-left-content-content,
.new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content.active-faq .new-ass-que-left-content-bottom {
  display: block;
}

.new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content,
.new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-bottom {
  display: none;
}

.NoData {
  display: none !important;
}

.imageRoate {
  transform: rotate(180deg);
}

.adminList {
  background: transparent !important;
  box-shadow: 0 6px 30px #0000000f !important;
  padding: 24px !important;
  margin-bottom: 10px !important;
  color: #000000 !important;
}

.new-morgage .sign-up-left h2 {
  width: 100%;
  font-size: 3rem;
}

.main-wrapper .assessment-bottom .question-header .add-new.add-script-btn {
  width: auto;
  padding: 0 14px;
}

.Disclaimer {
  font-size: 16px;
  color: #231f20;
  font-weight: 500;
  padding: 10px;
}

.form-control-main .tooltip-inner {
  width: 840px;
  max-width: unset !important;
  position: absolute;
  right: -85px;
  bottom: 7px;
  text-align: left;
}

.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul {
  padding: 0;
}

.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper
  .new-ass-que-left
  .new-ass-que-left-content
  .new-ass-que-left-content-content
  ul
  li.ass-questions {
  width: 100%;
  padding-right: 0;
  margin-bottom: 20px;
}

.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper
  .new-ass-que-left
  .new-ass-que-left-content
  .new-ass-que-left-content-content
  ul
  li.ass-questions
  p {
  color: #231f20;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
}

.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.answers {
  width: 100%;
  padding-right: 0;
  margin-bottom: 20px;
}

.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage {
  width: 100%;
  margin-bottom: 20px;
}

.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage textarea {
  width: 100%;
  min-height: 135px;
  border: 1px solid #dad9dc;
  border-radius: 0;
  padding: 10px;
}

.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper
  .new-ass-que-left
  .new-ass-que-left-content
  .new-ass-que-left-content-content
  ul
  li.answers
  button {
  width: calc(25% - 20px);
  margin-right: 20px;
  height: 39px;
}

.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper
  .new-ass-que-left
  .new-ass-que-left-content
  .new-ass-que-left-content-content
  ul
  li.answers
  .w-25 {
  border: 1px solid #231f20;
  height: 39px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper
  .new-ass-que-left
  .new-ass-que-left-content
  .new-ass-que-left-content-content
  ul
  li.answers
  .w-25
  input {
  border: none;
  width: calc(50% - 7px);
  border-right: 1px solid #231f20;
  border-radius: 0;
  margin-right: 7px;
}

.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper
  .new-ass-que-left
  .new-ass-que-left-content
  .new-ass-que-left-content-content
  ul
  li.answers
  .w-25
  select {
  border: none;
  width: 50%;
}

.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-right {
  width: 100%;
  padding-left: 0;
}

.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top {
  box-shadow: none !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
}

.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top .graph-wrapper {
  width: calc(50% - 20px);
  margin: 0 20px 20px 0;
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  padding: 20px;
}

.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-bottom {
  width: 30%;
}

.main-wrapper .nor-mor-detail-wrapper-bottom .assesment-button a {
  width: 160px;
}

.new-ass-que-wrapper.nor-mor-detail-wrapper-bottom .form-control-main {
  margin: 0;
  width: auto;
}

.main-wrapper .assessment-bottom .nor-mor-detail-wrapper-bottom .assesment-button div {
  margin-bottom: 20px;
}

.main-wrapper .new-ass-que-wrapper.nor-mor-detail-wrapper-bottom .assesment-button a {
  border: 1px solid #fa7a23;
  cursor: pointer;
}

.main-wrapper .new-ass-que-wrapper.nor-mor-detail-wrapper-bottom .assesment-button a:hover {
  background: transparent;
  color: #fa7a23;
}

.nor-mor-detail-wrapper-bottom .new-ass-que-left-wrapper .new-ass-que-left-right iframe {
  display: none;
}

.nor-mor-detail-wrapper-bottom .new-ass-que-left-wrapper .new-ass-que-left-right iframe:last-child {
  display: block;
}

.nor-mor-detail-wrapper-bottom .sign-in-btn button {
  height: 42px;
  line-height: 42px;
}

.model-popup-wrapper .model-popup .model-popup-content.tool-tip-scroller {
  max-height: calc(80vh - 35px);
  overflow-y: auto;
  margin-bottom: 0;
}
