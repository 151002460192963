* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: normal;
}
body {
  margin-bottom: 0;
  color: #231f20;
}
a,
a:hover {
  text-decoration: none;
  color: #ffffff;
}
img {
  max-width: 100%;
}
p,
ul,
li {
  margin: 0;
  padding: 0;
}
input:hover,
textarea:hover,
.form-control:hover,
select:hover,
input:focus,
textarea:focus,
.form-control:focus,
select:focus {
  box-shadow: none;
  outline: none;
}
::-webkit-scrollbar {
  width: 5px;
  background: #e4e1df;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #a2a2a2;
  width: 5px;
  border-radius: 10px;
}
.main-heading {
  font-size: 44px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 70px;
}
.sign-in-btn button {
  width: 180px;
  height: 50px;
  line-height: 50px;
  background: #fa7a23;
  border: 1px solid #fa7a23;
  color: #ffffff;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  transition: 0.5s;
}
.sign-in-btn button:hover {
  background: transparent;
  color: #fa7a23;
}
.form-control-main {
  margin-bottom: 24px;
  width: 100%;
  display: inline-block;
}
.form-control-main span {
  position: absolute;
  top: 14px;
  left: 15px;
}
.form-control-main .form-control-main1 {
  margin-bottom: 24px;
  width: 100%;
  display: inline-block;
}
.form-control-main .phoneInputCustom {
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  background: #ebebeb !important;
  border: none;
  padding-left: 70px !important;
  color: #040404;
  max-width: 95%;
}
.form-control-main .phoneInputCustom:hover,
.form-control-main .phoneInputCustom:focus {
  box-shadow: none;
}
.form-control-main .phoneInputCustom::-webkit-input-placeholder {
  color: #747474;
  font-size: 14px;
}
.form-control-main .phoneInputCustom:-ms-input-placeholder {
  color: 747474;
  font-size: 14px;
}
.form-control-main .phoneInputCustom::placeholder {
  color: 747474;
  font-size: 14px;
}
.form-control-main .custom {
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  background: blue !important;
  border: none;
  padding-left: 45px;
  color: #ffffff;
}
.form-control-main .custom:hover,
.form-control-main .custom:focus {
  box-shadow: none;
}
.form-control-main .custom::-webkit-input-placeholder {
  color: #747474;
  font-size: 14px;
}
.form-control-main .custom:-ms-input-placeholder {
  color: #747474;
  font-size: 14px;
}
.form-control-main .custom::placeholder {
  color: #747474;
  font-size: 14px;
}
.form-control-main .form-control {
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  background: #3c3939;
  border: none;
  padding-left: 45px;
  color: #ffffff;
}
.form-control-main .form-control:hover,
.form-control-main .form-control:focus {
  box-shadow: none;
}
.form-control-main .form-control::-webkit-input-placeholder {
  color: #747474;
  font-size: 14px;
}
.form-control-main .form-control:-ms-input-placeholder {
  color: #747474;
  font-size: 14px;
}
.form-control-main .form-control::placeholder {
  color: #747474;
  font-size: 14px;
}
.form-control-main input[type='number']::-webkit-outer-spin-button,
.form-control-main input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-control-main input[type='number'] {
  -moz-appearance: textfield;
}
.form-control-main .forget-password {
  color: #fa7a23;
  font-size: 16px;
  font-weight: 500;
}
.form-control-main.sign-in-btn {
  margin: 50px 0;
}
.sign-up-wrapper {
  background: #231f20;
  min-height: 100vh;
}
.sign-up-wrapper .sign-up-left {
  background: #ffffff;
  padding-top: 30px;
}
.sign-up-wrapper .sign-up-left .bottom-img {
  padding-top: 50px;
}
.sign-up-wrapper .sign-up-left .signup-text {
  margin: 50px 0 0 0;
}
.sign-up-wrapper .sign-up-left .signup-text h2 {
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  margin-bottom: 20px;
}
.sign-up-wrapper .sign-up-left .signup-text p {
  line-height: 24px;
  max-width: 50%;
  margin: 0 auto;
}
.sign-up-wrapper .sign-up-right {
  color: #ffffff;
}
.sign-up-wrapper .sign-up-right .sign-up-right-wrapper {
  max-width: 530px;
  margin: 0 auto;
  padding: 0 15px;
}
.sign-up-wrapper .sign-up-right .sign-up-right-wrapper .mobile-logo {
  display: none;
}
.sign-up-wrapper .sign-up-right p {
  font-size: 18px;
}
.sign-up-wrapper .sign-up-right p a {
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  transition: 0.5s;
}
.sign-up-wrapper .sign-up-right p a:hover {
  color: #fa7a23;
}
.main-wrapper .assessment-inner {
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  width: 100%;
}
.main-wrapper .assessment-inner .navigation {
  background: #000;
  width: 240px;
  color: #ffffff;
  padding: 10px;
  cursor: pointer;
}
.main-wrapper .assessment-inner .navigation .navigation-logo {
  padding-bottom: 50px;
}
.main-wrapper .assessment-inner .navigation nav .mobile-close {
  display: none;
}
.main-wrapper .assessment-inner .navigation nav ul li a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 25px;
}
.main-wrapper .assessment-inner .navigation nav ul li a span {
  color: #ffffff;
  padding-left: 15px;
  width: calc(100% - 35px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main-wrapper .assessment-inner .assessment-body {
  width: calc(100% - 240px);
}
.main-wrapper .assessment-topbar {
  background: #000;
  padding: 12px 30px;
}
.main-wrapper .assessment-topbar .mobile-header {
  display: none;
}
.main-wrapper .assessment-topbar .userinfo {
  font-weight: 600;
  color: #ffffff;
}
.main-wrapper .assessment-topbar .userinfo img {
  width: 40px;
  height: 40px;
}
.main-wrapper .assessment-topbar .userinfo .dropdown-toggle {
  padding: 0;
  border: none;
  color: #ffffff;
  font-size: 14px;
  padding-left: 15px;
  background: transparent;
}
.main-wrapper .assessment-topbar .userinfo .dropdown-toggle:hover,
.main-wrapper .assessment-topbar .userinfo .dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}
.main-wrapper .assessment-topbar .userinfo img {
  border-radius: 8px;
}
.main-wrapper .assessment-bottom {
  padding: 30px;
}
.main-wrapper .assessment-bottom .page-title {
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  margin-bottom: 20px;
}
.main-wrapper .assessment-bottom .page-title.new-question-title {
  margin-bottom: 0;
}
.main-wrapper .assessment-bottom .assement-header-bottom {
  margin-bottom: 12px;
}
.main-wrapper .assessment-bottom .assement-select {
  padding: 0 13px 0 60px;
  width: 357px;
}
.main-wrapper .assessment-bottom .assement-search {
  width: calc(100% - 447px);
}
.main-wrapper .assessment-bottom .assement-search input {
  border: 1px solid #231f20;
  width: 100%;
  height: 42px;
  padding-left: 20px;
  color: #231f20;
  transition: 0.5s;
}
.main-wrapper .assessment-bottom .assement-search input:hover,
.main-wrapper .assessment-bottom .assement-search input:focus {
  box-shadow: none;
  outline: none;
  border-color: #fa7a23;
}
.main-wrapper .assessment-bottom .assement-search input::-webkit-input-placeholder {
  color: #dad9dc;
  font-size: 14px;
}
.main-wrapper .assessment-bottom .assement-search input:-ms-input-placeholder {
  color: #dad9dc;
  font-size: 14px;
}
.main-wrapper .assessment-bottom .assement-search input::placeholder {
  color: #dad9dc;
  font-size: 14px;
}
.main-wrapper .assessment-bottom .profit-filter {
  width: auto;
  flex-grow: 1;
  margin: 1rem;
}
.main-wrapper .assessment-bottom .profit-filter select {
  border: 1px solid #231f20;
  width: 100%;
  height: 42px;
  padding-left: 20px;
  color: #231f20;
  transition: 0.5s;
}
.main-wrapper .assessment-bottom .profit-filter select:hover,
.main-wrapper .assessment-bottom .profit-filter select:focus {
  box-shadow: none;
  outline: none;
  border-color: #fa7a23;
}
.main-wrapper .assessment-bottom .profit-filter select::-webkit-input-placeholder {
  color: #dad9dc;
  font-size: 14px;
}
.main-wrapper .assessment-bottom .profit-filter select:-ms-input-placeholder {
  color: #dad9dc;
  font-size: 14px;
}
.main-wrapper .assessment-bottom .profit-filter select::placeholder {
  color: #dad9dc;
  font-size: 14px;
}
.main-wrapper .assessment-bottom .assesment-company {
  background: #2c5c84;
  padding: 16px;
}
.main-wrapper .assessment-bottom .assesment-company p {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}
.main-wrapper .assessment-bottom .assesment-company a {
  font-weight: 600;
  color: #ffffff;
  text-transform: capitalize;
}
.main-wrapper .assessment-bottom .assesment-company a span {
  padding-left: 10px;
}
.main-wrapper .assessment-bottom .assesment-table {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  padding: 17px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-header {
  background: #231f20;
  color: #ffffff;
  padding: 13px 25px;
}
.main-wrapper .assessment-bottom .assesment-table ul li {
  word-break: break-all;
}
.main-wrapper .assessment-bottom .assesment-table ul li.company-name {
  width: 16%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.email {
  width: 13%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.phone-number {
  width: 11%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.sign-up-date {
  width: 10%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.event-start {
  width: 13%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.event-end {
  width: 13%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.assessment {
  width: 6%;
  text-align: center;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.edit {
  width: 5%;
  text-align: center;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.reset {
  width: 5%;
  text-align: center;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.delete {
  width: 5%;
  text-align: center;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.action {
  width: 3%;
  text-align: right;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content {
  border: 1px solid #dad9dc;
  height: calc(100vh - 377px);
  overflow: auto;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content ul.table-main-content {
  padding: 20px 25px;
  cursor: pointer;
  border-bottom: 1px solid #dad9dc;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list {
  background: rgba(35, 31, 32, 0.06);
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #dad9dc;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top {
  padding: 12px 25px 12px 100px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .assessment-list-top-ul li {
  font-weight: 600;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .assessment-list-top-ul .top-assessments {
  width: 23%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .assessment-list-top-ul .top-created-date {
  width: 23%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .assessment-list-top-ul .top-updated-date {
  width: 34%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .assessment-list-top-ul .top-ass-btn {
  width: 20%;
  padding: 0 5px;
  text-align: right;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top a {
  height: 32px;
  line-height: 32px;
  background: #fa7a23;
  display: inline-block;
  width: 173px;
  text-align: center;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top a span {
  font-size: 12px;
  color: #ffffff;
  padding-left: 10px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom {
  border-top: 1px dashed #a2a2a2;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul {
  padding: 20px 25px 20px 100px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-name {
  width: 23%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-created-date {
  width: 23%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-updated-date {
  width: 34%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-edit {
  width: 6%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-download {
  width: 5%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-delete {
  width: 8%;
  padding: 0 5px 0 23px;
}
.main-wrapper .assessment-bottom .question-header .add-new {
  height: 40px;
  width: 160px;
  background: #fa7a23;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  text-transform: capitalize;
  margin-left: 15px;
}
.main-wrapper .assessment-bottom .question-header .add-new img {
  margin-left: 5px;
}
.main-wrapper .custom-radio {
  padding-right: 40px;
}
.main-wrapper .custom-radio .custom-radio-container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
}
.main-wrapper .custom-radio .custom-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.main-wrapper .custom-radio .custom-radio-container input:checked ~ .checkmark:after {
  display: block;
}
.main-wrapper .custom-radio .custom-radio-container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fa7a23;
}
.main-wrapper .custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #fa7a23;
}
.main-wrapper .custom-radio .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.main-wrapper .assesment-button {
  width: 90px;
}
.main-wrapper .assesment-button a {
  width: 90px;
  height: 42px;
  line-height: 42px;
  background: #fa7a23;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  display: inline-block;
}
.new-user-detail-wrapper {
  color: #ffffff;
  background: #231f20;
  padding: 18px 24px;
  margin-bottom: 24px;
}
.new-user-detail-wrapper a {
  color: #ffffff;
}
.new-user-detail-wrapper .new-user-detail-left {
  width: 50%;
  border-right: 1px solid #dad9dc;
  padding-right: 50px;
}
.new-user-detail-wrapper .new-user-detail-left .new-user-left-user {
  width: 50%;
}
.new-user-detail-wrapper .new-user-detail-left .new-user-left-user p {
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
  text-transform: capitalize;
}
.new-user-detail-wrapper .new-user-detail-left .new-user-left-content {
  width: 50%;
}
.new-user-detail-wrapper .new-user-detail-left .new-user-left-content ul li {
  width: 100%;
  margin-bottom: 15px;
}
.new-user-detail-wrapper .new-user-detail-left .new-user-left-content ul li p,
.new-user-detail-wrapper .new-user-detail-left .new-user-left-content ul li span {
  width: 50%;
  padding: 0 5px;
}
.new-user-detail-wrapper .new-user-detail-left .new-user-left-content ul li span {
  font-weight: 500;
}
.new-user-detail-wrapper .new-user-detail-right {
  width: 50%;
  padding-left: 50px;
}
.new-user-detail-wrapper .new-user-detail-right .new-user-right-current {
  width: 50%;
}
.new-user-detail-wrapper .new-user-detail-right .new-user-right-current ul li {
  width: 100%;
  margin-bottom: 15px;
}
.new-user-detail-wrapper .new-user-detail-right .new-user-right-current ul li p {
  width: 50%;
  padding: 0 5px;
}
.new-user-detail-wrapper .new-user-detail-right .new-user-right-current ul li label {
  width: 50%;
  padding: 0 5px;
  font-weight: 500;
}
.new-ass-que-wrapper .new-ass-que-left {
  width: 65%;
  max-height: 141vh;
  overflow-y: auto;
  overflow-x: unset;
}
.new-ass-que-wrapper .new-ass-que-left h2 {
  font-size: 26px;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0 0 15px 0;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  margin-bottom: 20px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul {
  padding: 0 24px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.ass-questions {
  width: calc(100% - 434px);
  padding-right: 30px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.acc-arrow {
  width: 24px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.hint {
  width: calc(60px - 30px);
  margin-right: 30px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.answers {
  width: 260px;
  padding-right: 30px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage {
  width: 90px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.tooltip-hint {
  position: relative;
  cursor: help;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.tooltip-hint:hover .tool-tip {
  display: block;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.tooltip-hint .tool-tip {
  position: absolute;
  background: #fff;
  padding: 10px;
  top: 0px;
  right: 38px;
  width: 250px;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #dad9dc;
  display: none;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.tooltip-hint .tool-tip:before {
  content: '';
  right: -9px;
  top: 3px;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #dad9dc;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head {
  background: #dad9dc;
  color: #231f20;
  text-transform: capitalize;
  padding: 14px 0;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li {
  font-weight: 600;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul {
  padding-top: 10px;
  padding-bottom: 10px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.ass-questions {
  line-height: 16px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers button {
  width: 70px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #231f20;
  text-transform: capitalize;
  font-size: 12px;
  background: transparent;
  text-align: center;
  display: inline-block;
  color: #231f20;
  margin-right: 10px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers button.active {
  background: #231f20;
  color: #ffffff;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers button:nth-child(3) {
  margin-right: 0;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers textarea {
  width: 100%;
  border: 1px solid #dad9dc;
  margin-top: 10px;
  padding: 8px;
  height: 52px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers textarea::-webkit-input-placeholder {
  /* Edge */
  color: #dad9dc;
  font-size: 12px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers textarea:-ms-input-placeholder {
  /* Internet Explorer */
  color: #dad9dc;
  font-size: 12px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers textarea::placeholder {
  color: #dad9dc;
  font-size: 12px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.percentage input,
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.percentage select {
  width: 90px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #dad9dc;
  font-size: 12px;
  text-align: center;
  display: inline-block;
  color: #231f20;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.percentage select {
  margin-bottom: 10px;
}
.new-ass-que-wrapper .new-ass-que-right {
  width: 35%;
  padding-left: 25px;
}
.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top {
  padding: 16px;
  margin-bottom: 10px;
  background: #231f20;
  color: #ffffff;
}
.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top .bar-chart-wrapper {
  width: 50%;
}
.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top .bar-chart-wrapper canvas {
  width: 100% !important;
  height: auto !important;
}
.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top .chart-container {
  max-width: 100%;
  overflow: auto;
}
.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-bottom {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  padding: 24px;
}
.new-ass-que-wrapper .graph-block {
  width: 35%;
  padding-left: 25px;
}
.new-ass-que-wrapper .graph-block .Graph-box {
  padding: 16px;
  margin-bottom: 10px;
  background: #231f20;
  color: #ffffff;
}
.new-ass-que-wrapper .graph-block .Graph-box .bar-chart-wrapper {
  width: 50%;
}
.new-ass-que-wrapper .graph-block .Graph-box .bar-chart-wrapper canvas {
  width: 100% !important;
  height: auto !important;
}
.new-ass-que-wrapper .graph-block .Graph-box .chart-container {
  max-width: 100%;
  overflow: auto;
}
.new-ass-que-wrapper .graph-block .new-ass-que-right-bottom {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  padding: 24px;
}
.question-bottom-wrapper .question-bottom-inner form {
  margin: 0 -25px;
}
.question-bottom-wrapper .question-bottom-inner form .from-group {
  width: 50%;
  padding: 0 25px;
  margin-bottom: 15px;
}
.question-bottom-wrapper .question-bottom-inner form .from-group input,
.question-bottom-wrapper .question-bottom-inner form .from-group select {
  height: 42px;
  border: 1px solid #dad9dc;
  font-size: 15px;
  border-radius: 0;
}
.question-bottom-wrapper .question-bottom-inner form .from-group select {
  width: 100%;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .add-new {
  height: 42px;
  width: 44px;
  background: #fa7a23;
  border: none;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .added-btn {
  height: 50px;
  width: 160px;
  background: #000;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .switch-text {
  font-size: 15px;
  font-weight: 500;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch {
  margin-left: 30px;
  padding-left: 0;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch input {
  height: 18px;
  width: 32px;
  border-radius: 10px;
  border: none;
  background-color: #ffd3b6;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch .switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch .switch input:checked + .slider {
  background-color: #fa7a23;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch .switch input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch .slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch .slider.round {
  border-radius: 34px;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch .slider.round:before {
  border-radius: 50%;
}
.question-list-wrapper {
  margin-top: 24px;
}
.question-list-wrapper .question-inner .question-title {
  margin-bottom: 15px;
}
.question-list-wrapper .question-inner .question-title h2 {
  font-size: 26px;
  line-height: 31px;
  font-weight: 700;
  margin-bottom: 0;
}
.question-list-wrapper .question-inner .question-list-main .question-list {
  margin-bottom: 16px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-head {
  background: #ffffff;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  padding: 17px 20px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-head h3 {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-head .up-arrow {
  display: none;
}
.question-list-wrapper .question-inner .question-list-main .question-list.active-question .question-list-head .up-arrow {
  display: block;
}
.question-list-wrapper .question-inner .question-list-main .question-list.active-question .question-list-head .down-arrow {
  display: none;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content {
  width: calc(50% - 40px);
  margin: 0 20px;
  padding: 25px 0;
  border-bottom: 1px dashed #dad9dc;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content:last-child,
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content:nth-last-child(2) {
  border-bottom: none;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-main {
  margin-bottom: 16px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-main p {
  width: calc(100% - 85px);
  padding-right: 10px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-main .question-action {
  width: 85px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-main .question-action .question-action-delete {
  margin-left: 50px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-hint {
  margin-bottom: 16px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-hint label {
  width: 52px;
  font-weight: 600;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-hint p {
  width: calc(100% - 52px);
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-bottom label {
  font-size: 12px;
  font-weight: 600;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-bottom p {
  padding-left: 10px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-bottom .question-percentage {
  margin-right: 50px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-bottom .question-percentage p {
  font-weight: 700;
  color: #2c5c84;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-bottom .question-status p {
  font-weight: 700;
  color: #fa7a23;
}
.question-list-wrapper .question-inner .question-list-main .question-list.active-question .question-list-head {
  background: #2c5c84;
  color: #ffffff;
}
.question-list-wrapper .question-inner .question-list-main .question-list.active-question .question-list-content-wrapper {
  border: 1px solid #2c5c84;
}
.model-popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.model-popup-wrapper .model-popup {
  width: 822px;
  background: #ffffff;
  padding: 34px;
  overflow-x: hidden;
}
.model-popup-wrapper .model-popup .model-popup-title {
  margin: 0 0 34px 0;
  flex-wrap: wrap;
  align-items: center;
}
.model-popup-wrapper .model-popup .model-popup-title h3 {
  font-size: 26px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 0;
}
.model-popup-wrapper .model-popup .model-popup-title .btn-close:focus {
  outline: none;
  box-shadow: none;
}
.model-popup-wrapper .model-popup .model-popup-content {
  margin-bottom: 30px;
}
.model-popup-wrapper .model-popup .model-popup-content .from-group input {
  height: 42px;
  border-radius: 0;
}
.model-popup-wrapper .model-popup .model-popup-footer .save-btn {
  width: 160px;
  height: 50px;
  line-height: 50px;
  background: #fa7a23;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
}
.modal-backdrop.fade.show {
  transition: unset;
}
.d-block.fade {
  transition: unset;
}
.modal.fade .modal-dialog {
  transition: unset;
}
.pdf-component canvas {
  height: auto !important;
  width: 100% !important;
}
.form-control-main select.form-control {
  background: #3c3939 url('/assets/images/select-down-arrow.svg') no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: 45%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.company-name {
  width: 23%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.sign-up-date {
  width: 15%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.email {
  width: 18%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.phone-number {
  width: 16%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.assessment {
  width: 10%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.edit {
  width: 5%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.reset {
  width: 5%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.delete {
  width: 5%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.action {
  width: 3%;
}
.sign-up-left img:first-child {
  max-width: 333px;
}
.main-wrapper .assessment-inner .navigation .navigation-logo img {
  max-width: 117px;
}
.agent-sign-up-wrapper {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}
.agent-sign-up-wrapper:before {
  content: '';
  background: url('/assets/images/agent-bottom-bg.svg') no-repeat;
  width: 367px;
  height: 366px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.agent-sign-up-wrapper:after {
  content: '';
  background: url('/assets/images/agent-top-bg.svg') no-repeat;
  width: 367px;
  height: 366px;
  position: absolute;
  right: 0;
  top: 0;
}
.agent-sign-up-wrapper .agent-sign-up-inner {
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 1;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left {
  width: calc(50% - 40px);
  margin-right: 40px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-top {
  margin-bottom: 70px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-bottom img,
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-bottom iframe {
  width: 100%;
  height: 450px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right {
  width: calc(50% - 40px);
  margin-left: 40px;
  background: #ffffff;
  box-shadow: 0px 10px 100px rgba(0, 0, 0, 0.06);
  padding: 72px 72px 56px 72px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right h2 {
  font-weight: 700;
  font-size: 34px;
  color: #231f20;
  margin-bottom: 60px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top {
  margin-bottom: 16px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  padding: 12px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-left {
  width: 50%;
  padding-right: 12px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-right {
  width: 50%;
  padding-left: 12px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-right ul {
  padding: 0;
  margin: 0;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-right ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-right ul li:last-child {
  margin-bottom: 0;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-right ul li p,
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-right ul li label {
  font-size: 14px;
  font-weight: normal;
  color: #231f20;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top p {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top label {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form {
  margin: 0 -12px;
  display: flex;
  flex-wrap: wrap;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main {
  width: calc(50% - 24px);
  margin: 0 12px 16px 12px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.address-input {
  width: calc(100% - 24px);
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.profit-signup p {
  font-size: 18px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.profit-signup p a {
  font-weight: 600;
  font-size: 18px;
  color: #231f20;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.profit-signup p a:hover {
  color: #fa7a23;
  transition-duration: 0.5s;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main span {
  top: 10px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main .form-control {
  background: #ebebeb;
  color: #231f20;
  height: 42px;
  line-height: 35px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.phoneInput .iti--allow-dropdown {
  width: 100%;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.phoneInput .iti--allow-dropdown .iti__flag-container .iti__selected-flag.dropdown-toggle {
  width: 100%;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.phoneInput .iti--allow-dropdown .country-dropdown {
  width: 230px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.phoneInput .iti--allow-dropdown .country-dropdown .iti__country-list li {
  position: relative;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.phoneInput .iti--allow-dropdown .country-dropdown .iti__country-list li .iti__country-name {
  left: 76px;
  top: 6px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.phoneInput .iti--allow-dropdown .country-dropdown .iti__country-list li .iti__dial-code {
  left: 37px;
  top: 6px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.phoneInput .phoneInputCustom {
  width: 100%;
  max-width: 100%;
  height: 42px;
  line-height: 35px;
  padding-left: 85px !important;
}
.height-sidebar-100 {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.scroll-sidebar-100 {
  height: 100vh;
  overflow: auto;
}
.phoneInput .agentLandingPhoneInput {
  height: 42px;
}
.signup-input-div.phoneInput .iti {
  width: 100%;
}
.signup-input-div.phoneInput .iti input {
  width: 100%;
}
.w-100 {
  width: 100% !important;
}
.w-px-100 {
  width: 100px !important;
}
.w-99 {
  width: 99% !important;
}
.w-px-99 {
  width: 99px !important;
}
.w-98 {
  width: 98% !important;
}
.w-px-98 {
  width: 98px !important;
}
.w-97 {
  width: 97% !important;
}
.w-px-97 {
  width: 97px !important;
}
.w-96 {
  width: 96% !important;
}
.w-px-96 {
  width: 96px !important;
}
.w-95 {
  width: 95% !important;
}
.w-px-95 {
  width: 95px !important;
}
.w-94 {
  width: 94% !important;
}
.w-px-94 {
  width: 94px !important;
}
.w-93 {
  width: 93% !important;
}
.w-px-93 {
  width: 93px !important;
}
.w-92 {
  width: 92% !important;
}
.w-px-92 {
  width: 92px !important;
}
.w-91 {
  width: 91% !important;
}
.w-px-91 {
  width: 91px !important;
}
.w-90 {
  width: 90% !important;
}
.w-px-90 {
  width: 90px !important;
}
.w-89 {
  width: 89% !important;
}
.w-px-89 {
  width: 89px !important;
}
.w-88 {
  width: 88% !important;
}
.w-px-88 {
  width: 88px !important;
}
.w-87 {
  width: 87% !important;
}
.w-px-87 {
  width: 87px !important;
}
.w-86 {
  width: 86% !important;
}
.w-px-86 {
  width: 86px !important;
}
.w-85 {
  width: 85% !important;
}
.w-px-85 {
  width: 85px !important;
}
.w-84 {
  width: 84% !important;
}
.w-px-84 {
  width: 84px !important;
}
.w-83 {
  width: 83% !important;
}
.w-px-83 {
  width: 83px !important;
}
.w-82 {
  width: 82% !important;
}
.w-px-82 {
  width: 82px !important;
}
.w-81 {
  width: 81% !important;
}
.w-px-81 {
  width: 81px !important;
}
.w-80 {
  width: 80% !important;
}
.w-px-80 {
  width: 80px !important;
}
.w-79 {
  width: 79% !important;
}
.w-px-79 {
  width: 79px !important;
}
.w-78 {
  width: 78% !important;
}
.w-px-78 {
  width: 78px !important;
}
.w-77 {
  width: 77% !important;
}
.w-px-77 {
  width: 77px !important;
}
.w-76 {
  width: 76% !important;
}
.w-px-76 {
  width: 76px !important;
}
.w-75 {
  width: 75% !important;
}
.w-px-75 {
  width: 75px !important;
}
.w-74 {
  width: 74% !important;
}
.w-px-74 {
  width: 74px !important;
}
.w-73 {
  width: 73% !important;
}
.w-px-73 {
  width: 73px !important;
}
.w-72 {
  width: 72% !important;
}
.w-px-72 {
  width: 72px !important;
}
.w-71 {
  width: 71% !important;
}
.w-px-71 {
  width: 71px !important;
}
.w-70 {
  width: 70% !important;
}
.w-px-70 {
  width: 70px !important;
}
.w-69 {
  width: 69% !important;
}
.w-px-69 {
  width: 69px !important;
}
.w-68 {
  width: 68% !important;
}
.w-px-68 {
  width: 68px !important;
}
.w-67 {
  width: 67% !important;
}
.w-px-67 {
  width: 67px !important;
}
.w-66 {
  width: 66% !important;
}
.w-px-66 {
  width: 66px !important;
}
.w-65 {
  width: 65% !important;
}
.w-px-65 {
  width: 65px !important;
}
.w-64 {
  width: 64% !important;
}
.w-px-64 {
  width: 64px !important;
}
.w-63 {
  width: 63% !important;
}
.w-px-63 {
  width: 63px !important;
}
.w-62 {
  width: 62% !important;
}
.w-px-62 {
  width: 62px !important;
}
.w-61 {
  width: 61% !important;
}
.w-px-61 {
  width: 61px !important;
}
.w-60 {
  width: 60% !important;
}
.w-px-60 {
  width: 60px !important;
}
.w-59 {
  width: 59% !important;
}
.w-px-59 {
  width: 59px !important;
}
.w-58 {
  width: 58% !important;
}
.w-px-58 {
  width: 58px !important;
}
.w-57 {
  width: 57% !important;
}
.w-px-57 {
  width: 57px !important;
}
.w-56 {
  width: 56% !important;
}
.w-px-56 {
  width: 56px !important;
}
.w-55 {
  width: 55% !important;
}
.w-px-55 {
  width: 55px !important;
}
.w-54 {
  width: 54% !important;
}
.w-px-54 {
  width: 54px !important;
}
.w-53 {
  width: 53% !important;
}
.w-px-53 {
  width: 53px !important;
}
.w-52 {
  width: 52% !important;
}
.w-px-52 {
  width: 52px !important;
}
.w-51 {
  width: 51% !important;
}
.w-px-51 {
  width: 51px !important;
}
.w-50 {
  width: 50% !important;
}
.w-px-50 {
  width: 50px !important;
}
.w-49 {
  width: 49% !important;
}
.w-px-49 {
  width: 49px !important;
}
.w-48 {
  width: 48% !important;
}
.w-px-48 {
  width: 48px !important;
}
.w-47 {
  width: 47% !important;
}
.w-px-47 {
  width: 47px !important;
}
.w-46 {
  width: 46% !important;
}
.w-px-46 {
  width: 46px !important;
}
.w-45 {
  width: 45% !important;
}
.w-px-45 {
  width: 45px !important;
}
.w-44 {
  width: 44% !important;
}
.w-px-44 {
  width: 44px !important;
}
.w-43 {
  width: 43% !important;
}
.w-px-43 {
  width: 43px !important;
}
.w-42 {
  width: 42% !important;
}
.w-px-42 {
  width: 42px !important;
}
.w-41 {
  width: 41% !important;
}
.w-px-41 {
  width: 41px !important;
}
.w-40 {
  width: 40% !important;
}
.w-px-40 {
  width: 40px !important;
}
.w-39 {
  width: 39% !important;
}
.w-px-39 {
  width: 39px !important;
}
.w-38 {
  width: 38% !important;
}
.w-px-38 {
  width: 38px !important;
}
.w-37 {
  width: 37% !important;
}
.w-px-37 {
  width: 37px !important;
}
.w-36 {
  width: 36% !important;
}
.w-px-36 {
  width: 36px !important;
}
.w-35 {
  width: 35% !important;
}
.w-px-35 {
  width: 35px !important;
}
.w-34 {
  width: 34% !important;
}
.w-px-34 {
  width: 34px !important;
}
.w-33 {
  width: 33% !important;
}
.w-px-33 {
  width: 33px !important;
}
.w-32 {
  width: 32% !important;
}
.w-px-32 {
  width: 32px !important;
}
.w-31 {
  width: 31% !important;
}
.w-px-31 {
  width: 31px !important;
}
.w-30 {
  width: 30% !important;
}
.w-px-30 {
  width: 30px !important;
}
.w-29 {
  width: 29% !important;
}
.w-px-29 {
  width: 29px !important;
}
.w-28 {
  width: 28% !important;
}
.w-px-28 {
  width: 28px !important;
}
.w-27 {
  width: 27% !important;
}
.w-px-27 {
  width: 27px !important;
}
.w-26 {
  width: 26% !important;
}
.w-px-26 {
  width: 26px !important;
}
.w-25 {
  width: 25% !important;
}
.w-px-25 {
  width: 25px !important;
}
.w-24 {
  width: 24% !important;
}
.w-px-24 {
  width: 24px !important;
}
.w-23 {
  width: 23% !important;
}
.w-px-23 {
  width: 23px !important;
}
.w-22 {
  width: 22% !important;
}
.w-px-22 {
  width: 22px !important;
}
.w-21 {
  width: 21% !important;
}
.w-px-21 {
  width: 21px !important;
}
.w-20 {
  width: 20% !important;
}
.w-px-20 {
  width: 20px !important;
}
.w-19 {
  width: 19% !important;
}
.w-px-19 {
  width: 19px !important;
}
.w-18 {
  width: 18% !important;
}
.w-px-18 {
  width: 18px !important;
}
.w-17 {
  width: 17% !important;
}
.w-px-17 {
  width: 17px !important;
}
.w-16 {
  width: 16% !important;
}
.w-px-16 {
  width: 16px !important;
}
.w-15 {
  width: 15% !important;
}
.w-px-15 {
  width: 15px !important;
}
.w-14 {
  width: 14% !important;
}
.w-px-14 {
  width: 14px !important;
}
.w-13 {
  width: 13% !important;
}
.w-px-13 {
  width: 13px !important;
}
.w-12 {
  width: 12% !important;
}
.w-px-12 {
  width: 12px !important;
}
.w-11 {
  width: 11% !important;
}
.w-px-11 {
  width: 11px !important;
}
.w-10 {
  width: 10% !important;
}
.w-px-10 {
  width: 10px !important;
}
.w-9 {
  width: 9% !important;
}
.w-px-9 {
  width: 9px !important;
}
.w-8 {
  width: 8% !important;
}
.w-px-8 {
  width: 8px !important;
}
.w-7 {
  width: 7% !important;
}
.w-px-7 {
  width: 7px !important;
}
.w-6 {
  width: 6% !important;
}
.w-px-6 {
  width: 6px !important;
}
.w-5 {
  width: 5% !important;
}
.w-px-5 {
  width: 5px !important;
}
.w-4 {
  width: 4% !important;
}
.w-px-4 {
  width: 4px !important;
}
.w-3 {
  width: 3% !important;
}
.w-px-3 {
  width: 3px !important;
}
.w-2 {
  width: 2% !important;
}
.w-px-2 {
  width: 2px !important;
}
.w-1 {
  width: 1% !important;
}
.w-px-1 {
  width: 1px !important;
}
.h-100 {
  height: 100% !important;
}
.h-99 {
  height: 99% !important;
}
.h-98 {
  height: 98% !important;
}
.h-97 {
  height: 97% !important;
}
.h-96 {
  height: 96% !important;
}
.h-95 {
  height: 95% !important;
}
.h-94 {
  height: 94% !important;
}
.h-93 {
  height: 93% !important;
}
.h-92 {
  height: 92% !important;
}
.h-91 {
  height: 91% !important;
}
.h-90 {
  height: 90% !important;
}
.h-89 {
  height: 89% !important;
}
.h-88 {
  height: 88% !important;
}
.h-87 {
  height: 87% !important;
}
.h-86 {
  height: 86% !important;
}
.h-85 {
  height: 85% !important;
}
.h-84 {
  height: 84% !important;
}
.h-83 {
  height: 83% !important;
}
.h-82 {
  height: 82% !important;
}
.h-81 {
  height: 81% !important;
}
.h-80 {
  height: 80% !important;
}
.h-79 {
  height: 79% !important;
}
.h-78 {
  height: 78% !important;
}
.h-77 {
  height: 77% !important;
}
.h-76 {
  height: 76% !important;
}
.h-75 {
  height: 75% !important;
}
.h-74 {
  height: 74% !important;
}
.h-73 {
  height: 73% !important;
}
.h-72 {
  height: 72% !important;
}
.h-71 {
  height: 71% !important;
}
.h-70 {
  height: 70% !important;
}
.h-69 {
  height: 69% !important;
}
.h-68 {
  height: 68% !important;
}
.h-67 {
  height: 67% !important;
}
.h-66 {
  height: 66% !important;
}
.h-65 {
  height: 65% !important;
}
.h-64 {
  height: 64% !important;
}
.h-63 {
  height: 63% !important;
}
.h-62 {
  height: 62% !important;
}
.h-61 {
  height: 61% !important;
}
.h-60 {
  height: 60% !important;
}
.h-59 {
  height: 59% !important;
}
.h-58 {
  height: 58% !important;
}
.h-57 {
  height: 57% !important;
}
.h-56 {
  height: 56% !important;
}
.h-55 {
  height: 55% !important;
}
.h-54 {
  height: 54% !important;
}
.h-53 {
  height: 53% !important;
}
.h-52 {
  height: 52% !important;
}
.h-51 {
  height: 51% !important;
}
.h-50 {
  height: 50% !important;
}
.h-49 {
  height: 49% !important;
}
.h-48 {
  height: 48% !important;
}
.h-47 {
  height: 47% !important;
}
.h-46 {
  height: 46% !important;
}
.h-45 {
  height: 45% !important;
}
.h-44 {
  height: 44% !important;
}
.h-43 {
  height: 43% !important;
}
.h-42 {
  height: 42% !important;
}
.h-41 {
  height: 41% !important;
}
.h-40 {
  height: 40% !important;
}
.h-39 {
  height: 39% !important;
}
.h-38 {
  height: 38% !important;
}
.h-37 {
  height: 37% !important;
}
.h-36 {
  height: 36% !important;
}
.h-35 {
  height: 35% !important;
}
.h-34 {
  height: 34% !important;
}
.h-33 {
  height: 33% !important;
}
.h-32 {
  height: 32% !important;
}
.h-31 {
  height: 31% !important;
}
.h-30 {
  height: 30% !important;
}
.h-29 {
  height: 29% !important;
}
.h-28 {
  height: 28% !important;
}
.h-27 {
  height: 27% !important;
}
.h-26 {
  height: 26% !important;
}
.h-25 {
  height: 25% !important;
}
.h-24 {
  height: 24% !important;
}
.h-23 {
  height: 23% !important;
}
.h-22 {
  height: 22% !important;
}
.h-21 {
  height: 21% !important;
}
.h-20 {
  height: 20% !important;
}
.h-19 {
  height: 19% !important;
}
.h-18 {
  height: 18% !important;
}
.h-17 {
  height: 17% !important;
}
.h-16 {
  height: 16% !important;
}
.h-15 {
  height: 15% !important;
}
.h-14 {
  height: 14% !important;
}
.h-13 {
  height: 13% !important;
}
.h-12 {
  height: 12% !important;
}
.h-11 {
  height: 11% !important;
}
.h-10 {
  height: 10% !important;
}
.h-9 {
  height: 9% !important;
}
.h-8 {
  height: 8% !important;
}
.h-7 {
  height: 7% !important;
}
.h-6 {
  height: 6% !important;
}
.h-5 {
  height: 5% !important;
}
.h-4 {
  height: 4% !important;
}
.h-3 {
  height: 3% !important;
}
.h-2 {
  height: 2% !important;
}
.h-1 {
  height: 1% !important;
}
.h-0 {
  height: 0% !important;
}
#landingContent {
  line-height: 30px;
}
.exclamation_mark {
  border: 1px solid orange;
  border-radius: 50%;
  padding: 0.1em 0.4em;
  color: orange;
  max-height: 22px;
  display: flex;
  align-items: center;
  max-width: 22px;
  width: 22px;
  justify-content: center;
  margin-right: 0.4rem;
}
.word-break {
  word-break: break-all;
  word-break: break-word;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-bottom {
  margin-top: 15px;
  padding: 0 15px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-bottom .next-btn {
  width: 120px;
  height: 46px;
  background: #fa7a23;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-bottom .prev-btn {
  width: 120px;
  height: 46px;
  background: #2c5c84;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content {
  padding-bottom: 15px;
}
.new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content.active-faq .new-ass-que-left-content-content,
.new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content.active-faq .new-ass-que-left-content-bottom {
  display: block;
}
.new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content,
.new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-bottom {
  display: none;
}
.NoData {
  display: none !important;
}
.imageRoate {
  transform: rotate(180deg);
}
.adminList {
  background: transparent !important;
  box-shadow: 0 6px 30px #0000000f !important;
  padding: 24px !important;
  margin-bottom: 10px !important;
  color: #000000 !important;
}
.new-morgage .sign-up-left h2 {
  width: 100%;
  font-size: 3rem;
}
.main-wrapper .assessment-bottom .question-header .add-new.add-script-btn {
  width: auto;
  padding: 0 14px;
}
.Disclaimer {
  font-size: 16px;
  color: #231f20;
  font-weight: 500;
  padding: 10px;
}
.form-control-main .tooltip-inner {
  width: 840px;
  max-width: unset !important;
  position: absolute;
  right: -85px;
  bottom: 7px;
  text-align: left;
}
.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul {
  padding: 0;
}
.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.ass-questions {
  width: 100%;
  padding-right: 0;
  margin-bottom: 20px;
}
.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.ass-questions p {
  color: #231f20;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
}
.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.answers {
  width: 100%;
  padding-right: 0;
  margin-bottom: 20px;
}
.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage {
  width: 100%;
  margin-bottom: 20px;
}
.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage textarea {
  width: 100%;
  min-height: 135px;
  border: 1px solid #dad9dc;
  border-radius: 0;
  padding: 10px;
}
.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers button {
  width: calc(25% - 20px);
  margin-right: 20px;
  height: 39px;
}
.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers .w-25 {
  border: 1px solid #231f20;
  height: 39px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers .w-25 input {
  border: none;
  width: calc(50% - 7px);
  border-right: 1px solid #231f20;
  border-radius: 0;
  margin-right: 7px;
}
.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers .w-25 select {
  border: none;
  width: 50%;
}
.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-right {
  width: 100%;
  padding-left: 0;
}
.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top {
  box-shadow: none !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
}
.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top .graph-wrapper {
  width: calc(50% - 20px);
  margin: 0 20px 20px 0;
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  padding: 20px;
}
.nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-bottom {
  width: 30%;
}
.main-wrapper .nor-mor-detail-wrapper-bottom .assesment-button a {
  width: 160px;
}
.new-ass-que-wrapper.nor-mor-detail-wrapper-bottom .form-control-main {
  margin: 0;
  width: auto;
}
.main-wrapper .assessment-bottom .nor-mor-detail-wrapper-bottom .assesment-button div {
  margin-bottom: 20px;
}
.main-wrapper .new-ass-que-wrapper.nor-mor-detail-wrapper-bottom .assesment-button a {
  border: 1px solid #fa7a23;
  cursor: pointer;
}
.main-wrapper .new-ass-que-wrapper.nor-mor-detail-wrapper-bottom .assesment-button a:hover {
  background: transparent;
  color: #fa7a23;
}
.nor-mor-detail-wrapper-bottom .new-ass-que-left-wrapper .new-ass-que-left-right iframe {
  display: none;
}
.nor-mor-detail-wrapper-bottom .new-ass-que-left-wrapper .new-ass-que-left-right iframe:last-child {
  display: block;
}
.nor-mor-detail-wrapper-bottom .sign-in-btn button {
  height: 42px;
  line-height: 42px;
}
.model-popup-wrapper .model-popup .model-popup-content.tool-tip-scroller {
  max-height: calc(80vh - 35px);
  overflow-y: auto;
  margin-bottom: 0;
}
:root {
  --primary-color: #7ed957;
  --primary-bg-color: #000;
  --color-white: #ffffff;
  --primary-font-color: #ffffffb2;
  --boder-color: #e2e8f0;
  --hover-color: #d94645;
  --secondery-bg-color: #0c0c0c;
  --form-fild-color: #ffffff14;
  --form-text-color: #52525266;
}
.min-100 {
  min-height: 100vh;
}
.text-uppercase {
  text-transform: uppercase;
}
.f-22-400 {
  font-size: 22px;
  font-weight: 400;
}
.container {
  width: 100%;
  max-width: 1310px;
  padding: 0 15px;
  margin: 0 auto;
}
.primary-btn {
  background: var(--primary-color);
  color: var(--primary-bg-color);
  font-weight: 700;
  border-radius: 12px;
  padding: 12px 43px;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: clamp(18px, 1.25vw, 24px);
  line-height: clamp(28px, 1.97vw, 30px);
}
.secondery-btn {
  display: inline-block;
  /* font-size: 18px; */
  font-weight: 300;
  /* line-height: 27px; */
  padding: 10px 24px;
  border: 0.87px solid var(--boder-color);
  color: var(--boder-color);
  border-radius: 35px;
  text-decoration: none;
  font-size: clamp(14px, 0.93vw, 18px);
  line-height: clamp(28px, 1.97vw, 27px);
}
.primary-bg {
  background: var(--primary-bg-color);
}
.secondery-bg {
  background: var(--secondery-bg-color);
}
/* list class  */
.list-style-none {
  list-style: none;
}
/* display class  */
.justify-spacebetween {
  justify-content: space-between;
}
.align-item-center {
  align-items: center;
}
/* margin class */
.mr2 {
  margin-right: 16px;
}
.mr3 {
  margin-right: 20px;
}
/* padding class */
.p5 {
  padding-top: 94px;
  padding-bottom: 94px;
}
.pl-2 {
  padding-left: 16px;
}
/* text class  */
.text-center {
  text-align: center;
}
.w-100 {
  width: 100%;
}
.f-30-700 {
  font-size: 30px;
  font-weight: 700;
  line-height: 33px;
}
.border-radius-8 {
  border-radius: 8px;
}
.ass-tabel {
  max-height: calc(100vh - 225px);
  overflow: auto;
}
.ass-tabel thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.ass-tabel tbody thead {
  position: unset;
  top: 0;
  z-index: 0;
}
/* heading class  */
.h2 {
  font-size: clamp(40px, 4.37vw, 84px);
  font-weight: 700;
  line-height: clamp(40px, 5.56vw, 126px);
  color: var(--color-white);
}
/* paragra class  */
.primary-font-color {
  color: var(--primary-font-color);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
}
.d-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.bg-main {
  background: #000;
}
.bg-secondary-only {
  background: #0f0f0f;
}
.w-40 {
  width: 40%;
}
.w-60 {
  width: 60%;
}
.f-18-400 {
  font-size: 18px;
  font-weight: 400;
}
.f-18-600 {
  font-size: 18px;
  font-weight: 600;
}
.f-20-700 {
  font-size: 20px;
  font-weight: 700;
}
.f-24-400 {
  font-size: 24px;
  font-weight: 400;
}
.f-16-400 {
  font-size: 16px;
  font-weight: 400;
}
.f-16-600 {
  font-size: 16px;
  font-weight: 600;
}
.f-42-700 {
  font-size: 42px;
  font-weight: 700;
}
.f-40-600 {
  font-size: 40px;
  font-weight: 600;
}
.text-white {
  color: #fff;
}
.text-color-pre {
  color: #7ed957;
}
.text-color1-pre {
  color: rgba(255, 255, 255, 0.6);
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-76 {
  margin-bottom: 76px;
}
.w-50 {
  width: 50%;
}
.w-100 {
  width: 100%;
}
.pr-3 {
  padding-right: 16px;
}
.mb-1 {
  margin-bottom: 4px;
}
.mb-3 {
  margin-bottom: 16px;
}
input:focus,
input:hover {
  outline: none;
  border: none;
}
.pre-sign-btn {
  border-radius: 10px;
  background: #7ed957;
  color: #050505;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  border: none;
  height: 54px;
}
.cursor-pointer {
  cursor: pointer;
}
.mb-5 {
  margin-bottom: 32px;
}
.text-center {
  text-align: center;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.mh-100vh {
  min-height: 100vh;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: end;
}
.w-33-only {
  width: 33.33%;
}
.prev-btn {
  border: 1px solid #fff;
  height: 44px;
  font-size: 16px;
  color: #fff;
  background: transparent;
  padding: 0 16px;
  border-radius: 10px;
}
.next-btn {
  border: 1px solid #fff;
  height: 44px;
  font-size: 16px;
  color: #050505;
  background: #fff;
  padding: 0 16px;
  border-radius: 10px;
}
.position-relative {
  position: relative;
}
.mw-730 {
  max-width: 730px;
}
.nav-sectoin {
  display: block;
  position: sticky;
  top: 0;
  padding: 20px 0;
  background: #000;
  z-index: 1111;
}
.nav-sectoin .container {
  position: relative;
}
.nav-sectoin .nav-bar.active .menu-list-container {
  position: absolute;
  top: 100%;
  background: var(--primary-bg-color);
  width: 100%;
  left: 0;
}
.nav-sectoin .nav-bar.active .menu-list {
  width: 100%;
  padding: 16px;
  display: block;
  border-bottom: 1.5px solid var(--boder-color);
}
.nav-sectoin .nav-bar.active .menu-list .list {
  width: 100%;
  padding: 12px 0;
  border-top: 0.5px solid var(--boder-color);
}
.nav-sectoin .nav-bar .menu-list .list {
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  color: var(--color-white);
  margin-right: clamp(35px, 3.64vw, 70px);
  transition-duration: 0.3s;
  font-size: clamp(14px, 1.04vw, 20px);
}
.nav-sectoin .nav-bar .menu-list .list.active,
.nav-sectoin .nav-bar .menu-list .list:hover {
  color: var(--hover-color);
  cursor: pointer;
  transition-duration: 0.3s;
}
.nav-sectoin .nav-bar .menu-list .list:last-child {
  margin-right: 0;
}
.banner-wrapper .left-wrapper {
  width: 46.39%;
  position: relative;
}
.banner-wrapper .left-wrapper .confidential {
  position: absolute;
  bottom: 28.48%;
  left: 15.1%;
  width: 69.65%;
}
.banner-wrapper .heading {
  margin-right: auto;
  width: 100%;
}
.banner-wrapper .heading h1 span {
  color: var(--primary-color);
}
.banner-wrapper .heading p {
  max-width: 755px;
  margin: 0 auto 40px auto;
  font-weight: 400;
  color: var(--color-white);
  font-size: 20px;
  line-height: 29px;
}
.banner-wrapper .heading .heading-video {
  margin: 0 auto 34px auto;
}
.banner-wrapper .heading .heading-video iframe {
  border: 5px solid #fff;
  border-radius: 6px;
}
.about-us-section {
  background: var(--secondery-bg-color);
}
.about-us-section .about-us-box {
  width: 50%;
}
.about-us-section .about-us-left {
  max-width: 746px;
  margin: 0 auto;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.about-us-section .about-us-right .about-us-righ-content {
  max-width: 660px;
}
.about-us-section .about-us-right .about-us-righ-content h2 {
  margin-bottom: 18px;
}
.about-us-section .about-us-right .about-us-righ-content p {
  font-weight: 400;
  margin-bottom: 80px;
  font-size: clamp(14px, 1.04vw, 24px);
  line-height: clamp(28px, 1.97vw, 36px);
}
.portfolio-section .heading {
  margin-bottom: 90px;
}
.portfolio-section .heading .heading-right {
  max-width: 640px;
}
.portfolio-section .heading .heading-right p {
  font-weight: 400;
  text-align: right;
  font-size: clamp(18px, 1.04vw, 24px);
  line-height: clamp(28px, 1.97vw, 30px);
}
.portfolio-section .portfolio-wrapper .wrapper-content {
  margin: 0 -69px;
}
.portfolio-section .portfolio-wrapper .wrapper-content .content-box {
  width: calc(50% - 138px);
  margin: 0 69px 53px;
}
.portfolio-section .portfolio-wrapper .wrapper-content .content-box:nth-of-type(even) {
  margin-top: 64px;
}
.portfolio-section .portfolio-wrapper .wrapper-content .content-box:nth-last-of-type(-n + 2) {
  margin-bottom: 0;
}
.portfolio-section .portfolio-wrapper .wrapper-content .content-box .content-box-bottom {
  padding-left: 24px;
  margin-top: 24px;
}
.portfolio-section .portfolio-wrapper .wrapper-content .content-box .content-box-bottom h3 {
  font-weight: 500;
  color: var(--color-white);
  margin-bottom: 20px;
  font-size: clamp(18px, 1.04vw, 24px);
  line-height: clamp(28px, 1.97vw, 36px);
}
.portfolio-section .portfolio-wrapper .wrapper-content .content-box div img {
  width: 100%;
}
.footer-section .footer-wrapper {
  padding: 46px 0;
  border-top: 1.2px solid var(--boder-color);
  text-align: center;
}
.footer-section .footer-wrapper p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26.4px;
}
.pre-signup-right .pre-signup-right-inner {
  max-width: 694px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
}
.pre-input {
  border-radius: 10px;
  background: #191919;
  /*	backdrop-filter: blur(16px);*/
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  height: 44px;
  width: 100%;
  padding-left: 10px;
  border: none;
}
.pre-signup-right .pre-signup-question-inner {
  max-width: 1034px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
}
.pre-signup-question-inner .invalid-feedback {
  display: block;
}
.primary-btn:hover {
  color: var(--primary-bg-color);
}
.ques-video video {
  border: 5px solid #fff;
  border-radius: 6px;
}
.ques-video iframe {
  border: 5px solid #fff;
  border-radius: 6px;
  background: #000;
}
.text-underline {
  text-decoration: underline;
}
select.pre-input {
  background-image: url('../../../assets/images/selec-down-white.svg');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
}
@media screen and (max-width: 1822px) {
  .portfolio-section .portfolio-wrapper .wrapper-content {
    margin: 0 -34.5px;
  }
  .portfolio-section .portfolio-wrapper .wrapper-content .content-box {
    width: calc(50% - 69px);
    margin: 0 34.5px 53px;
  }
}
@media screen and (max-width: 1753px) {
  .portfolio-section .portfolio-wrapper .wrapper-content {
    margin: 0 -15px;
  }
  .portfolio-section .portfolio-wrapper .wrapper-content .content-box {
    width: calc(50% - 34.5px);
    margin: 0 15px 53px;
  }
}
@media screen and (max-width: 1024px) {
  .portfolio-section .heading .heading-right,
  .about-us-section .about-us-right .about-us-righ-content {
    max-width: 100%;
  }
  .portfolio-section .heading .heading-right p {
    margin-top: 16px;
  }
}
@media screen and (max-width: 991px) {
  .banner-wrapper .heading {
    margin: 0 auto;
  }
  .about-us-section .about-us-box,
  .about-us-section .about-us-right .about-us-righ-content {
    width: 100%;
    margin-bottom: 16px;
  }
  .nav-sectoin {
    padding: 20px 0;
  }
  .banner-wrapper .left-wrapper {
    display: none;
  }
  .banner-wrapper .heading {
    max-width: 100%;
  }
  .banner-wrapper {
    background: none;
  }
  .footer-section .footer-wrapper {
    padding: 16px 0;
  }
  .p5 {
    padding-top: 47px;
    padding-bottom: 47px;
  }
}
@media screen and (max-width: 767px) {
  .menu-btn {
    display: none;
  }
  .nav-sectoin .nav-bar.active .menu-list-container {
    position: unset;
    width: unset;
  }
  .nav-sectoin .nav-bar.active .menu-list {
    display: flex;
    position: unset;
    width: unset;
    padding: unset;
    border: none;
  }
  .nav-sectoin .nav-bar.active .menu-list .list {
    width: unset;
    padding: unset;
    border: none;
  }
  .nav-sectoin .nav-bar.active .menu-list .list {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 767px) {
  .p5 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .pl-2 {
    padding-left: 0px;
  }
  .portfolio-section .portfolio-wrapper .wrapper-content {
    margin: 0;
  }
  .nav-sectoin .nav-bar .menu-list {
    display: none;
  }
  .nav-sectoin {
    padding: 16px 0;
  }
  .banner-wrapper .heading h1 span {
    display: inline-block;
  }
  .about-us-section .about-us-right .about-us-righ-content p {
    margin-bottom: 48px;
  }
  .banner-wrapper {
    background-size: cover;
  }
  .portfolio-section .heading .heading-right p {
    text-align: left;
  }
  .footer-section .footer-wrapper p {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 991px) {
  .w-100-991 {
    width: 100% !important;
  }
  .d-none-991 {
    display: none !important;
  }
  .bg-img-991 {
    background: url(/assets/images/bg/team-davis.png) no-repeat;
    background-size: 100%;
    background-position: bottom;
  }
}
@media (max-width: 767px) {
  .f-25-767 {
    font-size: 25px;
  }
  .cong-border:before {
    display: none;
  }
  .mb-30-767 {
    margin-bottom: 30px;
  }
  .w-100-767 {
    width: 100% !important;
  }
  .pr-0-767 {
    padding-right: 0;
  }
  .pl-0-767 {
    padding-left: 0;
  }
  .mt-5-767 {
    margin-top: 48px;
  }
  .mb-5-767 {
    margin-bottom: 48px;
  }
}
.assessment-main-wrap .p-datatable .p-datatable-thead > tr > th {
  background-color: #000;
  color: #fff;
  padding: 16px 5px;
}
.assessment-main-wrap .p-datatable .p-datatable-thead th.main-header:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.assessment-main-wrap .p-datatable .p-datatable-thead th.main-header:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.assessment-main-wrap .p-datatable .p-datatable-tbody > tr > td {
  padding: 4px 5px;
}
.add-new-assessment-btn {
  padding: 4px;
  line-height: 32px;
  background: #000;
  display: inline-block;
  width: 173px;
  text-align: center;
  border: none;
  color: #fff;
  border-radius: 8px;
}
.assessment-main-wrap .p-datatable .p-datatable-tbody td.new-assessment-td {
  text-align: end;
  border: none;
  padding-top: 12px;
}
.p-datatable .p-datatable-tbody > tr.assessment-list-top {
  background-color: rgba(35, 31, 32, 0.06);
}
.assessment-main-wrap .p-datatable .p-datatable-thead > tr > th.assessment-list-top.w-100px {
  width: 100px;
}
.assessment-main-wrap .p-datatable .p-datatable-thead > tr > th.assessment-list-top {
  background-color: rgba(35, 31, 32, 0.06);
  color: #222;
}
.assessment-main-wrap .p-datatable .p-datatable-tbody > tr > td.noRecord {
  text-align: center;
  padding: 10px;
}
@media (max-width: 767px) {
  .ass-tabel .p-element tr td:before {
    content: ' ' attr(data-th) ' : ';
    display: inline-block;
    font-weight: 600;
    margin-right: 10px;
    color: #222;
  }
  #pr_id_1 .p-datatable-tbody > tr > td {
    justify-content: unset !important;
  }
  .ass-tabel .p-element tr td.before-none-767:before {
    display: none;
  }
  .w-100-767 {
    width: 100%;
  }
  .p-2.w-100-767 {
    width: 100%;
    padding-left: 0 !important;
  }
  .p-button {
    padding: 5px 10px;
  }
}
@media (max-width: 1440px) {
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top {
    width: 100%;
  }
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-bottom {
    width: 100%;
  }
}
@media (max-width: 1366px) {
  .new-ass-que-wrapper .new-ass-que-left {
    width: 100%;
    margin-bottom: 20px;
  }
  .new-ass-que-wrapper .new-ass-que-right {
    width: 100%;
    padding-left: 0;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right {
    padding: 40px;
  }
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left {
    margin-bottom: 0;
  }
}
@media (max-width: 1200px) {
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-left {
    width: 100%;
    margin-bottom: 20px;
    padding-right: 0;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-right {
    width: 100%;
    padding-left: 0;
  }
}
@media (max-width: 1024px) {
  .main-wrapper .assessment-bottom .assesment-table {
    width: 100%;
    overflow: auto;
  }
  .main-wrapper .assessment-bottom .assesment-table .assesment-table-content {
    height: calc(100vh - 430px);
  }
  .main-wrapper .assessment-bottom .assesment-table-inner {
    width: 1200px;
  }
  .main-wrapper .assessment-bottom .assement-search {
    width: calc(100% - 100px);
    margin-bottom: 0;
    padding-right: 10px;
  }
  .main-wrapper .assessment-bottom .profit-filter {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
  }
  .main-wrapper .assessment-bottom .assement-select {
    padding-left: 0;
  }
  .main-wrapper .assessment-bottom .assesment-button {
    width: 90px;
    text-align: right;
  }
  .new-user-detail-wrapper .new-user-detail-left {
    width: 100%;
    padding-right: 0;
    border-right: none;
    margin-bottom: 20px;
  }
  .new-user-detail-wrapper .new-user-detail-left a {
    word-break: break-all;
  }
  .new-user-detail-wrapper .new-user-detail-right {
    width: 100%;
    padding-left: 0;
  }
}
@media (max-width: 991px) {
  .main-wrapper .assessment-bottom {
    padding: 20px;
  }
  .question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content {
    width: calc(100% - 40px);
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.ass-questions {
    width: calc(100% - 30px);
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.hint {
    margin-right: 0;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.answers {
    width: calc(100% - 80px);
    margin-top: 20px;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage {
    margin-top: 20px;
  }
  .model-popup-wrapper .model-popup-inner {
    width: 100%;
  }
  .model-popup-wrapper .model-popup {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
  .agent-sign-up-wrapper {
    height: auto;
  }
  .agent-sign-up-wrapper:after,
  .agent-sign-up-wrapper:before {
    background-size: 175px;
    height: 175px;
    width: 175px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left {
    width: 100%;
    margin: 40px 0;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-bottom img,
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-bottom iframe {
    height: 340px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-top {
    margin-bottom: 40px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right {
    width: 100%;
    margin: 0 0 50px 0;
  }
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.answers,
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage {
    margin-top: 0;
  }
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top .graph-wrapper {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .form-control-main .tooltip-inner {
    width: 360px;
  }
}
@media (max-width: 767px) {
  .sign-up-wrapper {
    min-height: 100vh;
  }
  .sign-up-wrapper .sign-up-left,
  .sign-up-wrapper .sign-up-right {
    width: 100% !important;
  }
  .sign-up-wrapper .sign-up-left {
    padding: 20px 15px;
    display: none;
  }
  .sign-up-wrapper .sign-up-right .sign-up-right-wrapper {
    padding: 20px 15px;
  }
  .sign-up-wrapper .sign-up-right .sign-up-right-wrapper .mobile-logo {
    display: block;
    margin-bottom: 30px;
  }
  .sign-up-wrapper .sign-up-right .sign-up-right-wrapper .mobile-logo img {
    width: 100px;
  }
  .main-heading {
    font-size: 35px;
    line-height: 38px;
    margin-bottom: 40px;
  }
  .form-control-main.sign-in-btn {
    margin: 30px 0;
  }
  body .main-wrapper .assessment-inner .navigation {
    display: none;
    padding: 20px 15px;
    position: absolute;
    cursor: pointer;
    top: 64px;
    border-top: 2px solid #ffffff;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  body .main-wrapper .assessment-inner .navigation .navigation-logo {
    display: none;
  }
  body .main-wrapper .assessment-inner .navigation nav .mobile-close {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  body .main-wrapper .assessment-inner .assessment-body {
    width: 100%;
  }
  body .main-wrapper .assessment-inner.menu-open {
    position: relative;
  }
  body .main-wrapper .assessment-inner.menu-open .navigation {
    display: block;
  }
  body .main-wrapper .assessment-topbar {
    background: #000;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
  }
  body .main-wrapper .assessment-topbar .mobile-header {
    display: block;
  }
  body .main-wrapper .assessment-topbar .mobile-header a {
    display: inline-block;
  }
  body .main-wrapper .assessment-topbar .mobile-header .mobile-logo {
    margin-left: 15px;
  }
  body .main-wrapper .assessment-topbar .mobile-header .mobile-logo img {
    height: 40px;
  }
  body .main-wrapper .assessment-topbar .userinfo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  body .main-wrapper .assessment-topbar .userinfo .dropdown-toggle span {
    white-space: nowrap;
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    text-align: left;
  }
  body .main-wrapper .assessment-bottom {
    padding: 20px 15px;
  }
  body .main-wrapper .assessment-bottom .assement-select {
    width: calc(100% - 90px);
  }
  body .main-wrapper .assessment-bottom .assesment-button {
    width: 90px;
    margin-left: 0 !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table-inner {
    width: unset;
  }
  body .main-wrapper .assessment-bottom .assesment-table {
    padding: 0;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-header {
    display: none;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content {
    height: unset;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content ul.table-main-content {
    padding: 10px 22px 10px 10px;
    position: relative;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top {
    padding: 10px;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .top-assessments,
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .top-created-date,
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .top-updated-date {
    display: none;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul {
    padding-left: 10px;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul li:before {
    display: inline-block;
    font-weight: 600;
    margin-right: 10px;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-name:before {
    content: 'Assessments : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-created-date:before {
    content: 'Created Date : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-updated-date:before {
    content: 'Updated Date : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-edit:before {
    content: 'Edit :';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-download:before {
    content: 'Download :';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-delete:before {
    content: 'Delete :';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li {
    width: 100% !important;
    padding: 5px 0 !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li:before {
    display: inline-block;
    font-weight: 600;
    margin-right: 10px;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.company-name:before {
    content: 'Loan Officer Name : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.email:before {
    content: 'Email : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.phone-number:before {
    content: 'phone-number : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.sign-up-date:before {
    content: 'Sign Up Date : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.event-end:before {
    content: 'Event End : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.edit {
    text-align: left;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.edit:before {
    content: 'Edit : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.reset {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.reset:before {
    content: 'Reset : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.new-action {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.new-action:before {
    content: 'Show Agent : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.new-action .showAgent-btn {
    display: inline-block;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.event-start:before {
    content: 'Event Start : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.assessment {
    text-align: left;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.assessment:before {
    content: 'Assessment : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.delete {
    text-align: left;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.delete:before {
    content: 'Action : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.action {
    position: absolute;
    top: 10px;
    right: 5px;
    width: auto !important;
  }
  body .main-wrapper .assessment-bottom .question-header .add-new:first-child {
    margin-left: 0;
  }
  body .main-wrapper .custom-radio {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px;
  }
  body .main-wrapper .custom-radio:last-child {
    margin-bottom: 0;
  }
  .question-bottom-wrapper .question-bottom-inner form .from-group {
    width: 100%;
  }
  .new-user-detail-wrapper {
    padding: 15px;
  }
  .new-user-detail-wrapper .new-user-detail-left .new-user-left-user {
    width: 100%;
    margin-bottom: 20px;
  }
  .new-user-detail-wrapper .new-user-detail-left .new-user-left-content {
    width: 100%;
  }
  .new-user-detail-wrapper .new-user-detail-right .new-user-right-current {
    width: 100%;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul {
    padding: 0 15px;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.answers {
    padding-right: 10px;
    width: 100%;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage {
    width: 100%;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage select {
    margin: 0 10px 0 0;
  }
  .model-popup-wrapper .model-popup {
    padding: 20px;
  }
  .agent-sign-up-wrapper:after,
  .agent-sign-up-wrapper:before {
    background-size: 100px;
    height: 100px;
    width: 100px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-top {
    margin-bottom: 20px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-top img {
    max-width: 150px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-bottom img,
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-bottom iframe {
    height: 250px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right {
    padding: 20px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right h2 {
    margin-bottom: 20px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main {
    width: calc(100% - 24px);
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.profit-signup p {
    width: 100%;
    margin-bottom: 16px;
  }
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers button {
    width: calc(33.33% - 10px);
    margin-right: 10px;
  }
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers button:last-child {
    width: 33.33%;
    margin-right: 0;
  }
  .nor-mor-detail-wrapper-bottom.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers .w-25 {
    width: 100% !important;
    margin-top: 16px;
  }
}
@media (max-width: 767px) {
  .new-ass-que-wrapper .user-input-div-details {
    width: 100%;
  }
  .new-ass-que-wrapper .user-details-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #231f20;
    color: #ffffff;
    padding: 16px;
  }
  h1 {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0;
  }
  .new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top {
    padding: 0;
  }
  .new-ass-que-wrapper .new-user-left-content {
    padding: 16px;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left {
    margin-top: 20px;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left h2 {
    font-size: 17px;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li.hint,
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li.answers,
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li.percentage {
    display: none;
  }
  .graph-container .graph-block {
    margin: 0;
  }
  .graph-container .Graph-box {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left + .new-ass-que-right .user-detail-form {
    display: none;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-inner > .user-details-header {
    padding: 0;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-right .user-detail-form.active-form .new-ass-que-right-top .new-user-left-content,
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-right .user-detail-form.active-form .new-ass-que-right-top .user-details-container {
    display: block;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-right .user-detail-form .new-ass-que-right-top .new-user-left-content,
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-right .user-detail-form .new-ass-que-right-top .user-details-container {
    display: none;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .landing-signup-container .signup-img iframe {
    width: 100%;
  }
  .signup-input-div.phoneInput .iti--allow-dropdown {
    width: 100%;
  }
  .signup-input-div.phoneInput .iti--allow-dropdown .agentLandingPhoneInput {
    width: 100%;
  }
  .signup-title h2 {
    font-size: 26px;
    line-height: 38px;
  }
  .question-list-wrapper .question-inner .question-list-main .question-list .question-list-head h3.word-break {
    width: calc(100% - 205px);
  }
  .question-list-wrapper .question-inner .question-list-main .question-list .question-list-head h3 ~ .d-flex {
    width: 205px;
  }
  .banner-wrapper .heading .heading-video iframe {
    height: auto;
  }
  .banner-wrapper .heading h2 {
    font-size: 22px;
  }
}
.LoaderModel {
  height: 100vh;
  width: 100vw !important;
  max-width: 100vw !important;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LoaderModel .modal-content {
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  background-color: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toastModelBody .modal {
  position: fixed;
  width: 0;
}
.toastModelBody .modal .fade {
  width: 0;
}
.toastModel {
  width: 0;
  display: flex;
  justify-content: flex-end;
}
.toastModel .modal-content {
  position: fixed;
  top: 12px;
  right: 12px;
  display: flex;
  justify-content: flex-end;
  width: 0;
}
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #FFFFFF;
}
.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
/*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}
.toast-container .toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}
.toast-container.toast-top-center .toastr,
.toast-container.toast-bottom-center .toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .toastr,
.toast-container.toast-bottom-full-width .toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.toastr {
  background-color: #030303;
  pointer-events: auto;
}
.toast-success {
  background-color: #51A351;
}
.toast-error {
  background-color: #BD362F;
}
.toast-info {
  background-color: #2F96B4;
}
.toast-warning {
  background-color: #F89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.add-new-ass-que-left {
  width: 65%;
  max-height: 141vh;
  overflow-y: auto;
  overflow-x: unset;
}
.new-ass-que-right .user-details-title {
  font-size: 26px;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0 0 6px 0;
}
.new-user-left-content {
  border-bottom: 1px dashed #dad9dc;
}
.new-user-left-content li {
  width: 100%;
  padding-bottom: 8px;
}
.user-details-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.user-input-div-details {
  width: 50%;
  padding: 5px 15px;
}
.user-input-div-details label {
  margin-bottom: 8px;
}
.user-input-div-details input {
  background: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 6px;
  width: 100%;
}
.span a {
  color: #ffffff;
}
.graph-container {
  width: 100%;
  display: inline-block;
}
.graph-block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  width: auto !important;
  padding: 0 !important;
}
.Graph-box {
  width: 100%;
  margin: 0 12px;
  padding: 20px !important;
  float: left;
  background: #ffffff !important;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  color: black !important;
}
.user-details-header {
  display: none;
}
